import {
    computed,
    defineComponent,
    onMounted, onUpdated,
} from 'vue';
import { useStore } from 'vuex';
import { gsap as gsapLib } from 'gsap';
import { SafeAny } from '@/types/safe-any.type';
import MediaHelper from '@/utils/media-helper';
import { Application } from '@/types/application.interface';

import Spinner from '@/components/Spinner/SpinnerComponent.vue';

export default defineComponent({
    name: 'DockComponent',
    components: {
        Spinner,
    },
    emits: [
        'openApp',
    ],
    setup(props, { emit }) {
        const gsap: GSAP = gsapLib;
        let isLoaded = false;
        const store: SafeAny = useStore();
        const mediaHelper = new MediaHelper();
        const applications = computed(() => {
            const isDesktop = !mediaHelper.isMobile();

            let apps = store.getters.get('applications')
                || [].splice(0, 6, null as never);

            if (isDesktop) {
                apps = apps.filter(
                    (app: Application) => app.desktop.show && app.desktop.showOnDock,
                );
            } else {
                apps = apps.filter(
                    (app: Application) => app.mobile.show && app.mobile.showOnDock,
                );
            }

            return apps;
        });

        const openApplication = (app: Application): void => {
            if (app.link) {
                window.open(app.link);
            }

            // windows open logic
            const isWindowActivatedBefore = store.getters.get('windows', app.id);
            const lastActiveWindow = store.getters.get('lastActiveWindow');
            if (isWindowActivatedBefore === -1) {
                store.dispatch('setCurrentWindow', app);
            }

            if (app.id !== 'launchpad' && lastActiveWindow?.id === app.id) {
                return;
            }

            if (app.id !== 'launchpad') {
                const el = document.getElementById(app.id);

                if (!app.isVisible && !mediaHelper.isMobile()) {
                    gsap.from(el, {
                        duration: 1,
                        y: -25,
                        ease: 'bounce',
                    });
                }

                store.dispatch('setCurrentWindow', app);
            }

            if (store.getters.get('applications', app.id)?.isVisible) {
                return;
            }

            store.dispatch('updateApplication', {
                ...app,
                isVisible: true,
            });

            emit('openApp', app);
        };

        const updateIcons = (pointer: number): void => {
            const items = document.querySelectorAll('.os-dock__item');

            const min = 50;
            const max = 85;
            const bound = min * Math.PI;

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < items.length; i++) {
                const icon = items[i];
                const distance = i * min + min / 2 - pointer;
                let x = 0;
                let scale = 1;

                if (-bound < distance && distance < bound) {
                    const rad = (distance / min) * 0.5;
                    scale = 1 + (max / min - 1) * Math.cos(rad);
                    x = 2 * (max - min) * Math.sin(rad);
                } else {
                    x = (-bound < distance ? 2 : -2) * (max - min);
                }

                gsap.to(icon, {
                    duration: 0.5,
                    x,
                    scale,
                });
            }
        };

        const bindDockHoverListener = (dock: Element, firstItem: HTMLElement): void => {
            dock?.addEventListener('mousemove', (e: Event) => {
                const offset = dock.getBoundingClientRect().left
                    + firstItem.offsetLeft;
                updateIcons((e as MouseEvent).clientX - offset);

                gsap.to(dock, {
                    paddingLeft: '4.5rem',
                    paddingRight: '4.5rem',
                });
            });
        };

        const bindDockMouseLeaveListener = (dock: Element, items: NodeListOf<Element>): void => {
            dock?.addEventListener('mouseleave', () => {
                gsap.to(items, {
                    duration: 0.5,
                    scale: 1,
                    x: 0,
                });

                gsap.to(dock, {
                    paddingLeft: '1.5rem',
                    paddingRight: '1.5rem',
                });
            });
        };

        const updated = (): void => {
            isLoaded = true;
            const isDesktop = !mediaHelper.isMobile();

            if (isDesktop) {
                const dock = document.querySelector('.os-dock__wrapper');
                const items = document.querySelectorAll('.os-dock__item');

                gsap.set(items, {
                    transformOrigin: 'bottom 20%',
                    width: 48,
                });

                if (dock && items && items[0]) {
                    bindDockHoverListener(dock, items[0] as HTMLElement);
                    bindDockMouseLeaveListener(dock, items);
                }
            }
        };

        const onLoad = (app: Application): void => {
            // eslint-disable-next-line no-param-reassign
            app.iconLoaded = true;
        };

        onMounted(() => {
            const dock = document.querySelector('.os-dock__wrapper');
            const items = document.querySelectorAll('.os-dock__item');

            if (dock && items[0]) {
                updated();
            }
        });

        onUpdated(() => {
            if (!isLoaded) {
                updated();
            }
        });

        return {
            mediaHelper,
            applications,
            openApplication,
            onLoad,
        };
    },
});

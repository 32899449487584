import { SafeAny } from '@/types/safe-any.type';

export const changeTheme = ({ commit }: SafeAny): void => {
    commit('changeTheme');
};

export const toggleAirdrop = ({ commit }: SafeAny): void => {
    commit('toggleAirdrop');
};

export const toggleBluetooth = ({ commit }: SafeAny): void => {
    commit('toggleBluetooth');
};

export const toggleFlightMode = ({ commit }: SafeAny): void => {
    commit('toggleFlightMode');
};

export const toggleCellularData = ({ commit }: SafeAny): void => {
    commit('toggleCellularData');
};

export const changeDisplayLevel = ({ commit }: SafeAny, payload: number): void => {
    commit('changeDisplayLevel', payload);
};

export const toggleMusic = ({ commit }: SafeAny): void => {
    commit('toggleMusic');
};

export const changeSoundLevel = ({ commit }: SafeAny, payload: number): void => {
    commit('changeSoundLevel', payload);
};

export const toggleWifi = ({ commit }: SafeAny): void => {
    commit('toggleWifi');
};

export const toggleFullscreen = ({ commit }: SafeAny): void => {
    commit('toggleFullscreen');
};

export const toggleTheme = ({ commit }: SafeAny): void => {
    commit('toggleTheme');
};

import {
    computed, defineComponent, onMounted, reactive, ref,
} from 'vue';
import { Store, useStore } from 'vuex';
import DateHelper from '@/utils/date-helper';
import { SafeAny } from '@/types/safe-any.type';
import { ConfigState } from '@/types/state.interface';
import { Application } from '@/types/application.interface';
import AppleIcon from '@/components/Icon/AppleIcon.vue';
import BatteryIcon from '@/components/Icon/BatteryIcon.vue';
import ControlCenterIcon from '@/components/Icon/ControlCenterIcon.vue';
import WifiIcon from '@/components/Icon/WifiIcon.vue';
import SignalIcon from '@/components/Icon/SignalIcon.vue';
import FlightModeIcon from '@/components/Icon/FlightModeIcon.vue';
import Dropdown from '@/components/Dropdown/DropdownComponent.vue';
import ControlCenter from '@/components/ControlCenter/ControlCenterComponent.vue';
import Window from '@/components/Window/WindowComponent.vue';

export default defineComponent({
    name: 'NavbarComponent',
    components: {
        AppleIcon,
        BatteryIcon,
        ControlCenterIcon,
        WifiIcon,
        SignalIcon,
        FlightModeIcon,
        Dropdown,
        ControlCenter,
        Window,
    },
    setup() {
        const store: Store<SafeAny> = useStore();
        const dateHelper: DateHelper = new DateHelper();
        const activeApplication = computed(() => store.getters.get('lastActiveWindow'));
        const date = ref(store.getters.get('isDesktop') ? dateHelper.formatDate() : dateHelper.formatDate(new Date(), {
            hour: '2-digit',
            minute: '2-digit',
        }));
        const showControlCenter = ref(false);
        const previousPointPosition = ref(1);
        const config: ConfigState = reactive(store.getters.get('config'));
        const telecom = ref('Vodafone');
        const modalType = ref('');

        const changeTheme = (): void => {
            store.dispatch('changeTheme')
                .then();
        };

        const mouseMoveEvent = (e: SafeAny): void => {
            const pageY = e.pageY || e.targetTouches?.[0]?.pageY;
            showControlCenter.value = (pageY + 10) > previousPointPosition.value;

            if (!showControlCenter.value) {
                document.removeEventListener('mousemove', mouseMoveEvent, true);
                document.removeEventListener('touchmove', mouseMoveEvent, true);
            }
        };

        const onDragEnd = (): void => {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            document.removeEventListener('mousemove', mouseMoveEvent, true);
            document.removeEventListener('touchmove', mouseMoveEvent, true);
        };

        const onDragStart = (e: SafeAny): void => {
            if (e.target.type === 'range') {
                return;
            }

            previousPointPosition.value = e.pageY || e.targetTouches?.[0].pageY;

            document.removeEventListener('mousemove', mouseMoveEvent, true);
            document.removeEventListener('touchmove', mouseMoveEvent, true);

            document.addEventListener('mousemove', mouseMoveEvent, true);
            document.addEventListener('touchmove', mouseMoveEvent, true);
        };

        const openModal = (id: string): void => {
            const app: Application = store.getters.get('applications', id);
            store.dispatch('setCurrentWindow', app);
            store.dispatch('updateApplication', {
                ...app,
                isVisible: true,
            });
        };

        onMounted(() => {
            const region = Intl.DateTimeFormat().resolvedOptions().timeZone?.split('/')?.[0];

            switch (region) {
            case 'Europe':
                telecom.value = 'Vodafone';
                break;
            case 'America':
                telecom.value = 'AT&T';
                break;
            default:
                telecom.value = 'Vodafone';
            }

            setInterval(() => {
                date.value = store.getters.get('isDesktop') ? dateHelper.formatDate() : dateHelper.formatDate(new Date(), {
                    hour: '2-digit',
                    minute: '2-digit',
                });
            }, 5000);
        });

        return {
            date,
            config,
            telecom,
            modalType,
            activeApplication,
            showControlCenter,
            openModal,
            onDragStart,
            onDragEnd,
            changeTheme,
        };
    },
});

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48.000000pt"
    height="48.000000pt"
    viewBox="0 0 48.000000 48.000000"
    preserveAspectRatio="xMidYMid meet">

    <g
      transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
      fill="#66b447"
      stroke="none">
      <path
        d="M153 379 c-100 -64 -99 -217 1 -278 73 -45 181 -19 225 53 45 73 19
181 -53 225 -45 28 -129 28 -173 0z m167 -80 c0 -6 -21 -42 -46 -81 l-45 -71
-35 34 c-32 31 -44 59 -26 59 5 0 17 -9 27 -20 10 -11 21 -20 25 -20 4 0 24
25 44 55 34 52 56 69 56 44z" />
    </g>
  </svg>
</template>

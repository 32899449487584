export const WINDOW_INITIAL_POSITIONS = {
    MESSAGES: {
        left: '10%',
        top: '15%',
    },
    SAFARI: {
        left: '0',
        top: '10%',
    },
    TERMINAL: {
        left: '35%',
        top: '15%',
    },
    VSCODE: {
        left: '25%',
        top: '15%',
    },
    ABOUT: {
        left: '35%',
        top: '25%',
    },
};

import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "px-5 py-14"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isDesktop)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent("Window"), {
          key: 0,
          id: _ctx.id,
          width: 860,
          height: 640
        }, {
          default: _withCtx(() => [
            (_ctx.isOnline)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent("MessageContent"), {
                  key: 0,
                  isLoading: _ctx.isLoading
                }, null, 8, ["isLoading"]))
              : (_openBlock(), _createBlock(_resolveDynamicComponent("Error"), { key: 1 }))
          ]),
          _: 1
        }, 8, ["id"]))
      : _createCommentVNode("", true),
    (!_ctx.isDesktop)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent("Application"), {
          key: 1,
          id: _ctx.id
        }, {
          default: _withCtx(() => [
            (_ctx.isOnline)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent("MessageContent"), { isLoading: _ctx.isLoading }, null, 8, ["isLoading"]))
                ]))
              : (_openBlock(), _createBlock(_resolveDynamicComponent("Error"), { key: 1 }))
          ]),
          _: 1
        }, 8, ["id"]))
      : _createCommentVNode("", true)
  ], 64))
}
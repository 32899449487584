import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import MediaHelper from '@/utils/media-helper';

import Error from '@/components/Error/ErrorComponent.vue';
import Window from '@/components/Window/WindowComponent.vue';
import Application from '@/components/Application/ApplicationComponent.vue';
import SafariContent from '@/components/Safari/Content/SafariContentComponent.vue';
import { SafeAny } from '@/types/safe-any.type';

export default defineComponent({
    name: 'SafariComponent',
    components: {
        Window,
        Application,
        SafariContent,
        Error,
    },
    setup() {
        const store: SafeAny = useStore();
        const mediaHelper = new MediaHelper();
        const isDesktop = computed(() => !mediaHelper.isMobile());
        const id = 'safari';
        const isOnline = computed(() => store.getters.get('isOnline'));

        return {
            isOnline,
            isDesktop,
            id,
        };
    },
});

import { Octokit } from '@octokit/core';

class GitService {
    octokit = new Octokit({ auth: process.env.VUE_APP_OCTOKIT_AUTH });

    owner = process.env.VUE_APP_OCTOKIT_OWNER;

    repo = process.env.VUE_APP_OCTOKIT_REPO;

    getCommits() {
        return this.octokit.request(`GET /repos/${this.owner}/${this.repo}/commits`);
    }
}

export default new GitService();

import {
    computed, defineComponent, onMounted, ref,
} from 'vue';
import { useStore } from 'vuex';
import { SafeAny } from '@/types/safe-any.type';
import { Skill } from '@/types/skill.interface';
import Spinner from '@/components/Spinner/SpinnerComponent.vue';

export default defineComponent({
    name: 'LaunchpadComponent',
    components: {
        Spinner,
    },
    setup() {
        const store: SafeAny = useStore();
        const iconsLoaded = false;
        const query = ref('');
        const skills = computed(() => {
            let ss = store.getters.get('skills');

            if (query.value) {
                ss = ss.filter((sk: Skill) => sk.label
                    && sk.label.toLowerCase().includes(query.value));
            }

            return ss;
        });

        const onLoad = (id: string): void => {
            const element = document.getElementById(id);
            const loadingElement = document.getElementById(`loading-${id}`);

            if (element) {
                element.style.opacity = '1';
            }

            if (loadingElement) {
                loadingElement.style.opacity = '0';
                loadingElement.style.display = 'none';
            }
        };

        onMounted(() => {
            const content = document.getElementById('content');

            content?.addEventListener('click', (e) => {
                const input = document.getElementById('input');

                if (e.target !== input) {
                    const launchpad = {
                        ...store.getters.get('applications', 'launchpad'),
                        isVisible: false,
                    };

                    store.dispatch('setApplication', launchpad);
                }
            });
        });

        return {
            query,
            skills,
            iconsLoaded,
            onLoad,
        };
    },
});

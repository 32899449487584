import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import { SafeAny } from '@/types/safe-any.type';

export default defineComponent({
    name: 'SliderComponent',
    props: {
        value: {
            type: Number,
            default: null,
        },
        actionType: {
            type: String,
            default: null,
        },
        min: {
            type: Number,
            default: 50,
        },
    },
    setup(props) {
        const store: SafeAny = useStore();

        const onChange = (value: number) => {
            store.dispatch(props.actionType, value);
        };

        return {
            onChange,
        };
    },
});

import { getRandomId } from '@/utils/random-helper';
import { SafeAny } from '@/types/safe-any.type';

export class BrowserHelper {
    isSafari(): boolean {
        return /apple/i.test(navigator.vendor);
    }

    checkBrowser(store: SafeAny): void {
        if (this.isSafari()) {
            store.dispatch('addNotification', {
                id: getRandomId().toString(),
                seen: false,
                title: 'Browser Support',
                body: 'Some animations may not be supported at all by your browser.',
                date: new Date().toISOString(),
                duration: 0,
                icon: 'WarningIcon',
            });
        }
    }
}

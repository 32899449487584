<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32.000000pt"
    height="32.000000pt"
    viewBox="0 0 32.000000 32.000000"
    preserveAspectRatio="xMidYMid meet">

    <g
      transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
      stroke="none">
      <path
        d="M96 268 c3 -13 13 -35 21 -50 13 -28 13 -28 -22 -28 -22 0 -38 6 -41
15 -4 8 -14 15 -23 15 -13 0 -16 -11 -16 -60 0 -49 3 -60 16 -60 9 0 19 7 23
15 3 9 19 15 41 15 35 0 35 0 22 -27 -8 -16 -18 -38 -21 -50 -13 -42 27 -26
69 27 36 46 43 50 81 50 49 0 74 21 54 45 -7 9 -29 15 -54 15 -38 0 -45 4 -81
50 -42 53 -82 69 -69 28z" />
    </g>
  </svg>
</template>

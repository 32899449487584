<template>
  <svg viewBox="0.992 24.634 499.008 239.57" width="499.008" height="239.57" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 388.936 33.162 L 56.936 33.162 C 30.536 33.162 8.936 54.762 8.936 81.162 L
              8.936 209.162 C 8.936 235.562 30.536 257.162 56.936 257.162 L 388.936 257.162 C
              415.336 257.162 436.936 235.562 436.936 209.162 L 436.936 81.162 C 436.936 54.762
              415.336 33.162 388.936 33.162 Z M 408.936 209.162 C 408.936 220.162 399.936 229.162
              388.936 229.162 L 56.936 229.162 C 45.936 229.162 36.936 220.162 36.936 209.162 L
              36.936 81.162 C 36.936 70.162 45.936 61.162 56.936 61.162 L 388.936 61.162 C 399.936
              61.162 408.936 70.162 408.936 81.162 L 408.936 209.162 Z" />
    <path
      d="M 456.936 93.762 L 456.936 196.562 C
        472.936 196.562 488.936 168.862 488.936 145.162 C 488.936 121.462 472.936 93.762
        456.936 93.762 Z" />
    <path
      d="M 235.666 80.886 L 62.013 80.886
        C 58.292 80.886 55.248 86.286 55.248 92.886 L 55.248 196.886 C 55.248 203.486 58.292
        208.886 62.013 208.886 L 235.666 208.886 C 239.387 208.886 242.432 203.486 242.432 196.886
        L 242.432 92.886 C 242.432 86.286 239.387 80.886 235.666 80.886 Z"
      style="" />
  </svg>
</template>

import { SafeAny } from '@/types/safe-any.type';

export const setCurrentWindow = ({ commit }: SafeAny, payload: SafeAny): void => {
    commit('setCurrentWindow', payload);
};

export const minimizeWindow = ({ commit }: SafeAny, payload: SafeAny): void => {
    commit('minimizeWindow', payload);
};

export const closeWindow = ({ commit }: SafeAny, payload: SafeAny): void => {
    commit('closeWindow', payload);
};

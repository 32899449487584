import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import App from '@/views/App/App.vue';
import Desktop from '@/views/Desktop/DesktopView.vue';
import MobileView from '@/views/Mobile/MobileView.vue';
import SplashScreen from '@/views/SplashScreen/SplashScreen.vue';
import store from '@/store';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: App,
        meta: { transition: 'fade' },
        children: [
            {
                path: '/desktop',
                component: Desktop,
            },
            {
                path: '/mobile',
                component: MobileView,
            },
            {
                path: '/splashscreen',
                component: SplashScreen,
                meta: { transition: 'fade' },
            },
        ],
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.path !== '/splashscreen' && !store?.getters.get('isAuthenticated')) next({ path: '/splashscreen' });
    else next();
});

export default router;

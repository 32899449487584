import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-screen h-screen bg-cover bg-ios dark:bg-ios-dark px-5" }
const _hoisted_2 = { class: "grid grid-rows-7 grid-cols-4 md:grid-cols-5 xl:grid-cols-7 pt-10" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["id", "src"]
const _hoisted_5 = { class: "text-gray-200 text-lg w-full flex items-end justify-center" }
const _hoisted_6 = { class: "absolute bottom-1 left-5 right-5 flex" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent("Navbar"))),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.apps, (app) => {
        return (_openBlock(), _createElementBlock("a", {
          class: "flex flex-wrap mb-10",
          href: app?.link,
          target: "_blank",
          key: app.id
        }, [
          _withDirectives((_openBlock(), _createElementBlock("img", {
            id: app.icon,
            key: app.icon,
            src: app.icon,
            alt: "app-icon",
            class: "max-w-[5.5rem] max-h-[5.5rem] drop-shadow-xl object-cover m-auto",
            onLoad: _cache[0] || (_cache[0] = $event => (_ctx.onLoad()))
          }, null, 40, _hoisted_4)), [
            [_vShow, _ctx.iconsLoaded]
          ]),
          (!_ctx.iconsLoaded)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent("Spinner"), {
                class: "flex items-center justify-center w-[5.5rem] h-[5.5rem] m-auto",
                key: 'loading-' + app.id,
                id: 'loading-' + app.id
              }, null, 8, ["id"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, _toDisplayString(app.label), 1)
        ], 8, _hoisted_3))
      }), 128))
    ]),
    (_ctx.isMessagesOpen)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent("Messages"), { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.isSafariOpen)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent("Safari"), { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.isFilesOpen)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent("Files"), { key: 2 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(), _createBlock(_resolveDynamicComponent("Dock")))
    ])
  ]))
}
import { SafeAny } from '@/types/safe-any.type';

const get = (state: SafeAny) => (key: string, innerKey?: string): SafeAny => {
    if (!key) {
        return state;
    }

    if (innerKey) {
        if (Array.isArray(state[key])) {
            return state[key].find((st: SafeAny) => st.id === innerKey);
        }

        return state[key][innerKey];
    }

    return state[key];
};

export default {
    get,
};

export default class MediaHelper {
    getBreakpoint(): string {
        let breakpoint = '';
        const screen = window.innerWidth;

        if (screen < 640) {
            breakpoint = 'xs';
        } else if (screen >= 640 && screen < 768) {
            breakpoint = 'sm';
        } else if (screen >= 768 && screen < 1024) {
            breakpoint = 'md';
        } else if (screen >= 1024 && screen < 1280) {
            breakpoint = 'lg';
        } else if (screen >= 1280 && screen < 1536) {
            breakpoint = 'xl';
        } else if (screen >= 1536 && screen < 1920) {
            breakpoint = '2xl';
        } else if (screen >= 1920) {
            breakpoint = '3xl';
        }

        return breakpoint;
    }

    isMobile(): boolean {
        const screen = window.innerWidth;

        return screen < 1280;
    }
}

import { Skill } from '@/types/skill.interface';
import { SafeAny } from '@/types/safe-any.type';

export const getSkills = ({ commit }: SafeAny): void => {
    commit('getSkills');
};

export const setSkills = ({ commit }: SafeAny, skills: Skill[]): void => {
    commit('setSkills', skills);
};

import { getRandomId } from '@/utils/random-helper';
import { SafeAny } from '@/types/safe-any.type';
import { Notification } from '@/types/notification.interface';

export function checkNetworkSpeed(store: SafeAny): void {
    const timeout = setTimeout(() => {
        store.dispatch('addNotification', {
            id: getRandomId().toString(),
            seen: false,
            title: 'Network Speed',
            body: 'Some parts of application may not be served as expected due to internet speed.',
            date: new Date().toISOString(),
            duration: 0,
            icon: 'WarningIcon',
        });
    }, 2000);

    window.addEventListener('load', () => {
        clearTimeout(timeout);
    });
}

export function checkIfOnline(store: SafeAny): void {
    const notification: Notification = {
        id: getRandomId().toString(),
        application: '',
        date: new Date().toISOString(),
        seen: false,
        title: 'Network',
        body: 'It seems like you\'re offline. Please go back to online for expedition.',
        duration: 0,
        icon: 'WifiOffIcon',
    };

    window.addEventListener('online', () => {
        notification.date = new Date().toISOString();
        store.dispatch('closeNotification', notification);
        store.dispatch('setOnlineStatus', true);
    });

    window.addEventListener('offline', () => {
        notification.date = new Date().toISOString();
        store.dispatch('closeNotification', notification);
        store.dispatch('addNotification', notification);
        store.dispatch('setOnlineStatus', false);
    });
}

<template>
  <svg
    stroke="currentColor"
    fill="none"
    stroke-width="2"
    viewBox="0 0 24 24"
    stroke-linecap="round"
    stroke-linejoin="round"
    height="16"
    width="16"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 11a9 9 0 0 1 9 9"
    />
    <path
      d="M4 4a16 16 0 0 1 16 16"
    />
    <circle
      cx="5"
      cy="19"
      r="1"
    />
  </svg>
</template>

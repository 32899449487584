export default class DateHelper {
    formatDate(date = new Date(), options: {
        month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' | undefined;
        day?: 'numeric' | '2-digit' | undefined;
        year?: 'numeric' | '2-digit' | undefined;
        hour?: 'numeric' | '2-digit' | undefined;
        minute?: 'numeric' | '2-digit' | undefined;
        weekday?: 'long' | 'short'
    } = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    }): string {
        return new Intl.DateTimeFormat(navigator.language, options).format(date);
    }

    getRelativeTime(date: string): string {
        if (!date) {
            return '';
        }

        let output = '';
        const d = new Date();
        const currentTimestamp = Math.floor(d.getTime() / 1000);
        const seconds = currentTimestamp - (new Date(date).getTime() / 1000);

        if (seconds === undefined) {
            return d.toString();
        }

        if (seconds < 30) {
            // Less than a minute has passed:
            output = 'now';
        } else if (seconds < 60) {
            output = 'Less than a minute';
        } else if (seconds < 3600) {
            // Less than an hour has passed:
            output = `${Math.floor(seconds / 60)} minutes ago`;
        } else if (seconds < 86400) {
            // Less than a day has passed:
            output = `${Math.floor(seconds / 3600)} hours ago`;
        } else if (seconds < 2620800) {
            // Less than a month has passed:
            output = `${Math.floor(seconds / 86400)} days ago`;
        } else if (seconds < 31449600) {
            // Less than a year has passed:
            output = `${Math.floor(seconds / 2620800)} months ago`;
        } else {
            // More than a year has passed:
            output = `${Math.floor(seconds / 31449600)} years ago`;
        }

        return output;
    }
}

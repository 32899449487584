import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "absolute flex" }
const _hoisted_4 = {
  key: 0,
  class: "mx-auto"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createBlock(_Transition, {
    name: "fade",
    mode: "out-in",
    appear: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["absolute flex min-w-[300px] rounded-2xl shadow-2xl bg-transparent border-[1px] border-gray-400/50 dark:border-neutral-700 overflow-hidden", { 'min-h-[400px]': _ctx.$props.height !== 0 }]),
        id: _ctx.id + '-window',
        style: _normalizeStyle({
             width: typeof _ctx.$props.width === 'string' ? _ctx.$props.width : _ctx.$props.width + 'px',
             height: _ctx.$props.height === 0 ? 'auto' : _ctx.$props.height + 'px',
             'z-index': _ctx.data.zIndex,
             left: _ctx.$props.width === 0 ? 0 : _ctx.data.getLeft,
             top: _ctx.data.getTop
        })
      }, [
        _createElementVNode("div", {
          class: "relative w-full rounded-lg shadow-2xl overflow-hidden",
          onClick: _cache[8] || (_cache[8] = $event => (_ctx.bringToFront()))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["px-5 flex items-center absolute top-0 left-0 z-10 w-full", { 'py-3 text-neutral-900 dark:text-gray-200 bg-gray-200 dark:bg-neutral-900': _ctx.showTitle, 'py-6': !_ctx.showTitle }]),
            id: _ctx.id + '-header',
            onMouseleave: _cache[3] || (_cache[3] = $event => (_ctx.isDraggingActive ? _ctx.endDrag($event) : $event.preventDefault())),
            onMouseout: _cache[4] || (_cache[4] = $event => (_ctx.endDrag($event))),
            onMousedown: _cache[5] || (_cache[5] = $event => (_ctx.startDrag($event))),
            onMouseup: _cache[6] || (_cache[6] = $event => (_ctx.endDrag($event))),
            onMousemove: _cache[7] || (_cache[7] = $event => (_ctx.drag($event)))
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("button", {
                class: "group mr-3 rounded-full border-none outline-none w-4 h-4 flex items-center justify-center bg-red-500",
                name: "close-button",
                onClick: _cache[0] || (_cache[0] = $event => (_ctx.close()))
              }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent("CloseIcon"), { class: "hidden group-hover:block" }))
              ]),
              _createElementVNode("button", {
                class: "group mr-3 rounded-full border-none outline-none w-4 h-4 flex items-center justify-center bg-yellow-500",
                name: "minimize-button",
                onClick: _cache[1] || (_cache[1] = $event => (_ctx.minimize()))
              }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent("MinimizeIcon"), { class: "hidden group-hover:block" }))
              ]),
              _createElementVNode("button", {
                class: "group mr-3 rounded-full border-none outline-none w-4 h-4 flex items-center justify-center bg-green-500",
                name: "maximize-button",
                onClick: _cache[2] || (_cache[2] = $event => (_ctx.maximize()))
              }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent("MaximizeIcon"), { class: "hidden group-hover:block" }))
              ])
            ]),
            (_ctx.showTitle)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.setTitle(_ctx.id)), 1)
                ]))
              : _createCommentVNode("", true)
          ], 42, _hoisted_2),
          _createElementVNode("div", {
            class: _normalizeClass(["flex h-full overflow-y-hidden", { 'pt-10': _ctx.showTitle }])
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 2)
        ])
      ], 14, _hoisted_1)
    ]),
    _: 3
  }))
}
import { UserCredential, signInAnonymously, getAuth } from 'firebase/auth';
import {
    DocumentData, collection, getFirestore, query, getDocs, QuerySnapshot, orderBy,
} from 'firebase/firestore';
import { initializeApp } from 'firebase/app';

const firebaseApp = initializeApp({
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
});

const db = getFirestore(firebaseApp);

class FirebaseService {
    getCollection(col: string, order?: string): Promise<QuerySnapshot<DocumentData>> {
        if (order) {
            return getDocs(query(collection(db, col), orderBy(order)));
        }

        return getDocs(query(collection(db, col)));
    }

    authorize(): Promise<UserCredential> {
        return signInAnonymously(getAuth());
    }
}

export default new FirebaseService();

import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1de61574"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "os-slider__wrapper" }
const _hoisted_2 = { class: "os-slider__icon" }
const _hoisted_3 = ["min", "value"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("input", {
      type: "range",
      min: _ctx.$props.min,
      max: "100",
      class: "os-slider",
      value: _ctx.$props.value,
      tabindex: "-1",
      onInput: _cache[0] || (_cache[0] = $event => (_ctx.onChange(+$event.target.value)))
    }, null, 40, _hoisted_3)
  ]))
}
import {
    computed, defineComponent, onMounted, reactive, ref,
} from 'vue';
import { useStore } from 'vuex';
import { SafeAny } from '@/types/safe-any.type';
import FirebaseService from '@/services/firebase.service';

import Spinner from '@/components/Spinner/SpinnerComponent.vue';
import ArrowLeftIcon from '@/components/Icon/ArrowLeftIcon.vue';
import ArrowRightIcon from '@/components/Icon/ArrowRightIcon.vue';
import SidebarIcon from '@/components/Icon/SidebarIcon.vue';
import ShareIcon from '@/components/Icon/ShareIcon.vue';
import BookmarkIcon from '@/components/Icon/BookmarkIcon.vue';
import TabsIcon from '@/components/Icon/TabIcon.vue';

export default defineComponent({
    name: 'SafariContentComponent',
    components: {
        Spinner,
        ArrowLeftIcon,
        ArrowRightIcon,
        SidebarIcon,
        ShareIcon,
        BookmarkIcon,
        TabsIcon,
    },
    setup() {
        const store: SafeAny = useStore();
        const website = reactive(ref(''));
        const url = reactive(ref('bookmarks'));
        const isLoading = reactive(ref(true));
        const links = computed(() => store.getters.get('applications', 'safari')?.links);

        const getLinks = () => {
            ['workplace', 'hobby', 'education']
                .forEach((collectionName) => {
                    FirebaseService.getCollection(collectionName)
                        .then((snapshot) => {
                            const data: SafeAny = [];

                            snapshot.forEach((doc) => {
                                const docData = doc.data();

                                data.push({
                                    label: docData.label,
                                    icon: docData.icon,
                                    url: docData.url,
                                    description: docData.description,
                                });
                            });

                            if (data && data.length > 0) {
                                store.dispatch('updateApplication', {
                                    id: 'safari',
                                    links: {
                                        ...store.getters.get('applications', 'safari')?.links,
                                        [collectionName]: {
                                            title: collectionName,
                                            content: [...data],
                                        },
                                    },
                                });
                            }

                            isLoading.value = false;
                        });
                });
        };

        computed(() => {
            const online = store.getters.get('isOnline');

            if (online) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                isLoading.value = true;
                getLinks();
            }

            return online;
        });

        const goToUrl = () => {
            url.value = `https://www.bing.com/search?q=${website.value}`;
        };

        const goToBookmarks = () => {
            url.value = 'bookmarks';
            website.value = '';
        };

        onMounted(() => getLinks());

        return {
            website,
            url,
            isLoading,
            links,
            goToUrl,
            goToBookmarks,
        };
    },
});

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-624b63dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "absolute top-12 right-4 bottom-4 w-96 p-3 overflow-y-auto overflow-x-hidden no-scrollbar h-full" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "flex flex-nowrap"
}
const _hoisted_4 = { class: "mr-2 w-1/6 flex justify-center items-center" }
const _hoisted_5 = { class: "w-5/6" }
const _hoisted_6 = { class: "flex flex-wrap w-full" }
const _hoisted_7 = { class: "flex w-full items-center" }
const _hoisted_8 = {
  key: 0,
  class: "text-neutral-900 dark:text-white font-semibold"
}
const _hoisted_9 = {
  key: 1,
  class: "absolute right-5 text-xs"
}
const _hoisted_10 = { class: "w-full mt-1" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  key: 0,
  class: "flex items-center mb-0.5"
}
const _hoisted_13 = ["src"]
const _hoisted_14 = {
  key: 1,
  class: "text-neutral-600 dark:text-white"
}
const _hoisted_15 = {
  key: 2,
  class: "ml-auto"
}
const _hoisted_16 = { key: 2 }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, { name: "fade" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "group mb-2.5 p-3 w-full rounded-xl border-[1px] border-black/20 dark:border-white/10 backdrop-filter backdrop-blur-3xl bg-gray-100/75 dark:bg-zinc-800/70 z-40 relative",
            key: notification.id,
            onClick: _cache[0] || (_cache[0] = $event => (_ctx.onClick()))
          }, [
            _createElementVNode("button", {
              class: "hidden group-hover:flex absolute p-1 left-[-5px] top-[-5px] rounded-full border-[1px] border-black/20 dark:border-white/10 backdrop-filter backdrop-blur-3xl bg-gray-300/70 dark:bg-zinc-800/90",
              name: "close-notification-button",
              onClick: $event => (_ctx.onClose(notification))
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent("CloseIcon"), { class: "fill-white" }))
            ], 8, _hoisted_2),
            (!notification.application)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    (notification.icon)
                      ? (_openBlock(), _createBlock(_resolveDynamicComponent(notification.icon), {
                          key: 0,
                          class: "w-3/4 h-3/4"
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        (notification?.title)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(notification?.title?.toUpperCase()), 1))
                          : _createCommentVNode("", true),
                        (notification?.relativeTime && notification?.title)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(notification?.relativeTime), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        (notification?.body)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              class: "text-neutral-600 dark:text-gray-400",
                              innerHTML: notification?.body
                            }, null, 8, _hoisted_11))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ])
                ]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (notification?.title || notification.icon || notification.application)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        (notification?.icon)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              class: "w-7 h-7 mr-3",
                              alt: "close-icon",
                              src: notification?.icon
                            }, null, 8, _hoisted_13))
                          : _createCommentVNode("", true),
                        (notification?.application)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(notification?.application?.toUpperCase()), 1))
                          : _createCommentVNode("", true),
                        (notification?.relativeTime && notification?.title)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(notification?.relativeTime), 1))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  (notification?.title)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(["text-neutral-900 dark:text-white font-semibold", { 'mt-2': notification.icon || notification.application || notification.date }])
                      }, _toDisplayString(notification?.title?.toUpperCase()), 3))
                    : _createCommentVNode("", true),
                  (notification?.body)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(notification?.body), 1))
                    : _createCommentVNode("", true)
                ], 64))
          ]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}
import { State } from '@/types/state.interface';
import { SafeAny } from '@/types/safe-any.type';

export const setCurrentWindow = (state: State, payload: SafeAny): void => {
    let maxZIndex = Object.keys(state.windows).length > 0
        ? Math.max(...Object.values(state.windows)) : 0;

    if (maxZIndex > 48) {
        Object.keys(state.windows)
            .forEach((key) => {
                state.windows[key] -= state.windows[key] - 1;
                maxZIndex = state.windows[key];
            });
    }

    state.windows[payload.id] = maxZIndex + 1;
    state.applications[payload.id] = {
        ...state.applications[payload.id],
        isVisible: true,
    };
    state.lastActiveWindow = payload;
};

export const minimizeWindow = (state: State, id: string): void => {
    state.windows[id] = -1;
};

export const closeWindow = (state: State, id: string): void => {
    const applicationIndex = state.applications.findIndex((app) => app.id === id);

    if (applicationIndex > -1) {
        state.applications[applicationIndex] = {
            ...state.applications[applicationIndex],
            isVisible: false,
        };
    }

    state.lastActiveWindow = null;
};

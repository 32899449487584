import { createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e4c3ae46"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h-full w-full flex items-center justify-center bg-gray-200 dark:bg-neutral-800" }
const _hoisted_2 = ["src"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent("Window"), {
    id: 'vscode',
    width: 1280,
    height: 1080,
    showTitle: true
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("iframe", {
          src: 'https://github1s.com/alicandirik/portfolio-readme/blob/v5/README.md',
          height: "100%",
          width: "100%",
          allowfullscreen: ""
        }, null, 8, _hoisted_2)
      ])
    ]),
    _: 1
  }))
}
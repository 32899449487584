import { SafeAny } from '@/types/safe-any.type';

export * from './control-center/control-center.actions';
export * from './application/application.actions';
export * from './user/user.actions';
export * from './window/window.actions';
export * from './skill/skill.actions';
export * from './notification/notification.actions';
export * from './message/message.actions';

export const setViewType = ({ commit }: SafeAny, payload: boolean): void => {
    commit('setViewType', payload);
};

export const setVersion = ({ commit }: SafeAny, payload: number): void => {
    commit('setVersion', payload);
};

export const setOnlineStatus = ({ commit }: SafeAny, payload: boolean): void => {
    commit('setOnlineStatus', payload);
};

import { computed, defineComponent, reactive } from 'vue';
import { useStore } from 'vuex';
import { ConfigState } from '@/types/state.interface';
import { SafeAny } from '@/types/safe-any.type';
import WifiIcon from '@/components/Icon/WifiIcon.vue';
import WifiOffIcon from '@/components/Icon/WifiOffIcon.vue';
import BluetoothIcon from '@/components/Icon/BluetoothIcon.vue';
import AirdropIcon from '@/components/Icon/AirdropIcon.vue';
import ThemeIcon from '@/components/Icon/ThemeIcon.vue';
import KeyboardBrightnessIcon from '@/components/Icon/KeyboardBrightnessIcon.vue';
import FullscreenIcon from '@/components/Icon/FullscreenIcon.vue';
import DisplayIcon from '@/components/Icon/DisplayIcon.vue';
import SoundIcon from '@/components/Icon/SoundIcon.vue';
import PlayIcon from '@/components/Icon/PlayIcon.vue';
import PauseIcon from '@/components/Icon/PauseIcon.vue';
import SignalIcon from '@/components/Icon/SignalIcon.vue';
import BatteryIcon from '@/components/Icon/BatteryIcon.vue';
import FlightModeIcon from '@/components/Icon/FlightModeIcon.vue';
import CellularDataIcon from '@/components/Icon/CellularDataIcon.vue';
import HalfBatteryIcon from '@/components/Icon/HalfBatteryIcon.vue';
import CalculatorIcon from '@/components/Icon/CalculatorIcon.vue';
import Slider from '@/components/Slider/SliderComponent.vue';

export default defineComponent({
    name: 'ControlCenterComponent',
    components: {
        WifiIcon,
        WifiOffIcon,
        BluetoothIcon,
        AirdropIcon,
        ThemeIcon,
        KeyboardBrightnessIcon,
        FullscreenIcon,
        DisplayIcon,
        SoundIcon,
        PlayIcon,
        PauseIcon,
        Slider,
        SignalIcon,
        BatteryIcon,
        FlightModeIcon,
        CellularDataIcon,
        HalfBatteryIcon,
        CalculatorIcon,
    },
    setup() {
        const store: SafeAny = useStore();
        const config: ConfigState = reactive(store.getters.get('config'));
        const isOnline = computed(() => store.getters.get('isOnline'));

        const toggle = (actionType: string) => {
            store.dispatch(actionType);
        };

        return {
            config,
            isOnline,
            toggle,
        };
    },
});

import {
    computed, defineComponent, onMounted,
} from 'vue';
import { useStore } from 'vuex';
import { SafeAny } from '@/types/safe-any.type';
import { Notification } from '@/types/notification.interface';
import DateHelper from '@/utils/date-helper';
import CloseIcon from '@/components/Icon/CloseIcon.vue';
import WarningIcon from '@/components/Icon/WarningIcon.vue';
import WifiOffIcon from '@/components/Icon/WifiOffIcon.vue';
import CheckIcon from '@/components/Icon/CheckIcon.vue';

export default defineComponent({
    name: 'NotificationComponent',
    components: {
        CloseIcon,
        WarningIcon,
        WifiOffIcon,
        CheckIcon,
    },
    emits: [
        'onClick',
    ],
    setup(setup, { emit }) {
        const dateHelper = new DateHelper();
        const store: SafeAny = useStore();

        const check = (ns: Notification[]) => ns.map((notification: Notification) => ({
            ...notification,
            relativeTime: dateHelper.getRelativeTime(notification.date),
        }));

        const notifications = computed(() => {
            let ns = store.getters.get('notifications')?.filter((n: Notification) => !n.seen);

            ns = check(ns);

            return ns;
        });

        const onClose = (notification: Notification): void => store.dispatch('closeNotification', notification);

        const onClick = () => emit('onClick');

        onMounted(() => {
            setInterval(() => {
                let unseenNotifications = store.getters.get('notifications');
                unseenNotifications = check(unseenNotifications);

                unseenNotifications.forEach((n: Notification) => store.dispatch('updateNotification', n));
            }, 45000);
        });

        return {
            notifications,
            onClick,
            onClose,
        };
    },
});

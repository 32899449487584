import {
    computed, defineComponent, onMounted, reactive, ref,
} from 'vue';
import { useStore } from 'vuex';
import { Message } from '@/types/message.interface';
import { SafeAny } from '@/types/safe-any.type';
import GitService from '@/services/git.service';
import MediaHelper from '@/utils/media-helper';

import Window from '@/components/Window/WindowComponent.vue';
import MessageContent from '@/components/Message/Content/MessageContentComponent.vue';
import Application from '@/components/Application/ApplicationComponent.vue';
import Error from '@/components/Error/ErrorComponent.vue';

export default defineComponent({
    name: 'MessageComponent',
    components: {
        Window,
        MessageContent,
        Application,
        Error,
    },
    setup() {
        const store: SafeAny = useStore();
        const mediaHelper = new MediaHelper();
        const isDesktop = computed(() => !mediaHelper.isMobile());
        const isLoading = ref(true);

        const getCommits = (): void => {
            GitService.getCommits()
                .then(({ data }) => {
                    const stateMessages: Message = store.getters.get('applications', 'messages');
                    const commitsIndex = stateMessages?.messages?.findIndex((m) => m.sender === 'Github');
                    const updated: SafeAny = { ...stateMessages };
                    updated.messages[commitsIndex].messages = [...data]?.sort(
                        (a, b) => new Date(a?.commit?.author?.date).getTime()
                            - new Date(b?.commit?.author?.date).getTime(),
                    );
                    updated.messages[commitsIndex].unreadMessageCount = data.length;

                    store.dispatch('updateApplication', updated.messages);
                    isLoading.value = false;
                })
                .catch((e: SafeAny) => {
                    console.log(`%cCATCH ERROR >> ${e}`, 'background-color:teal;color:white;font-size:1rem');
                });
        };

        const isOnline = computed(() => {
            const online = store.getters.get('isOnline');

            if (online) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                isLoading.value = true;
                store.dispatch('getMessages');
                getCommits();
            }

            return online;
        });
        const selectedMessageData = reactive(ref(null));
        const id = 'messages';

        const onSelect = (data: SafeAny): void => {
            selectedMessageData.value = data;
        };

        onMounted(() => {
            store.dispatch('getMessages');
            getCommits();
        });

        return {
            id,
            isOnline,
            isLoading,
            isDesktop,
            selectedMessageData,
            onSelect,
        };
    },
});

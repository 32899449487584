import {
    computed, defineComponent, onMounted, reactive, ref,
} from 'vue';
import { useStore } from 'vuex';
import { SafeAny } from '@/types/safe-any.type';
import firebaseService from '@/services/firebase.service';
import { Skill } from '@/types/skill.interface';

import Spinner from '@/components/Spinner/SpinnerComponent.vue';
import Application from '@/components/Application/ApplicationComponent.vue';
import Accordion from '@/components/Accordion/AccordionComponent.vue';
import Error from '@/components/Error/ErrorComponent.vue';

export default defineComponent({
    name: 'FilesComponent',
    components: {
        Spinner,
        Application,
        Accordion,
        Error,
    },
    setup() {
        const store: SafeAny = useStore();
        const isLoading = reactive(ref(false));
        const iconsLoaded = reactive(ref(false));
        const query = reactive(ref(''));
        const isOnline = computed(() => store.getters.get('isOnline'));
        const content = computed(() => {
            const result = [];
            let skills = store.getters.get('skills');
            let certificates = store.getters.get('applications', 'terminal')?.content && store.getters.get('applications', 'terminal')?.content[1]?.content;

            if (query.value) {
                skills = skills.filter((sk: SafeAny) => sk.label
                    && sk.label.toLowerCase().includes(query.value.toLowerCase()));
                certificates = certificates.filter((c: SafeAny) => c.toLowerCase()
                    .includes(query.value.toLowerCase()));
            }

            if (skills?.length > 0) {
                result.push({ label: 'Skills', content: skills });
            }

            if (certificates?.length > 0) {
                result.push({ label: 'Certificates', content: certificates });
            }

            return result;
        });

        const getCertificates = () => {
            firebaseService.getCollection('terminal')
                .then((snapshot) => {
                    const certificates: SafeAny = [];

                    snapshot.forEach((doc) => {
                        certificates.push(doc.data());
                    });
                    store.dispatch('updateApplication', {
                        id: 'terminal',
                        content: [...certificates],
                    });

                    isLoading.value = false;
                });
        };

        const getSkills = () => {
            firebaseService.getCollection('skill')
                .then((snapshot) => {
                    const skills: Skill[] = [];

                    snapshot.forEach((doc) => {
                        skills.push({
                            id: doc.id,
                            label: doc.data().label,
                            icon: doc.data().icon,
                        });
                    });

                    store.dispatch('setSkills', skills);

                    isLoading.value = false;
                });
        };

        onMounted(() => {
            getSkills();
            getCertificates();
        });

        return {
            isOnline,
            content,
            query,
            iconsLoaded,
        };
    },
});

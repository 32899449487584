import {
    defineComponent, PropType, reactive, ref, onUpdated,
} from 'vue';

type DropdownListPosition = 'left' | 'right' | undefined;

export default defineComponent({
    name: 'DropdownComponent',
    props: {
        placement: {
            type: String as PropType<DropdownListPosition>,
            default: 'left',
        },
        visible: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
            default: 'component',
        },
        menuWidth: {
            type: String,
            default: 'w-56',
        },
    },
    setup(props) {
        const reactives = reactive({
            isDropdownOpen: ref(false),
            visible: ref(null),
        });

        const bindListListener = (listEl: NodeListOf<Element> | undefined) => {
            if (!listEl || listEl.length === 0) {
                return;
            }

            listEl?.forEach((item) => {
                item.addEventListener('click', (e) => {
                    const target = e.target as HTMLElement;

                    if (!props.visible && reactives.isDropdownOpen && item.contains(target)) {
                        reactives.isDropdownOpen = false;
                    }
                });
            });
        };

        const bindOutsideListener = (dropdownEl: HTMLElement | null)
            : void => {
            if (!dropdownEl) {
                return;
            }

            window.addEventListener('click', (e) => {
                const target = e.target as HTMLElement;

                if (reactives.isDropdownOpen
                    && !dropdownEl.contains(target)
                ) {
                    reactives.isDropdownOpen = false;
                }
            });
        };

        onUpdated(() => {
            const dropdown = document.getElementById(`${props.id}-dropdown`);
            const dropdownMenu = document.getElementById(`${props.id}-dropdown-menu`);
            const items = dropdownMenu?.querySelectorAll('li:not(.os-navbar__system-menu__divider)');

            bindListListener(items);
            bindOutsideListener(dropdown);
        });

        const toggleDropdown = (): void => {
            reactives.isDropdownOpen = !reactives.isDropdownOpen;
        };

        return {
            reactives,
            toggleDropdown,
        };
    },
});

import {
    defineComponent, onMounted, reactive, ref,
} from 'vue';
import { useStore } from 'vuex';
import { SafeAny } from '@/types/safe-any.type';
import FirebaseService from '@/services/firebase.service';
import Window from '@/components/Window/WindowComponent.vue';
import Spinner from '@/components/Spinner/SpinnerComponent.vue';

export default defineComponent({
    name: 'TerminalComponent',
    components: {
        Window,
        Spinner,
    },
    setup() {
        const store: SafeAny = useStore();
        const isLoading = reactive(ref(true));
        const history: SafeAny = [];
        let currDirPath: SafeAny = [];
        let terminal: SafeAny = null;
        let currChildren: SafeAny = null;
        const currInputTimes = ref(0);
        let currHistory: SafeAny;
        let commands = {};

        const getConfigs = () => {
            FirebaseService.getCollection('terminal')
                .then((snapshot) => {
                    const config: SafeAny = [];

                    snapshot.forEach((doc) => {
                        config.push(doc.data());
                    });

                    if (config && config.length > 0) {
                        store.dispatch('updateApplication', {
                            id: 'terminal',
                            content: [...config],
                        });
                    }

                    currChildren = config;
                    terminal = config;
                }).finally(() => {
                    isLoading.value = false;
                });
        };

        const reset = () => {
            const terminalEl = document.querySelector('#terminal-content');

            if (terminalEl) {
                terminalEl.innerHTML = '';
            }
        };

        const getCurrDirName = () => {
            if (currDirPath.length === 0) {
                return '~';
            }

            return currDirPath[currDirPath.length - 1];
        };

        const getCurrChildren = () => {
            let children = terminal;

            // eslint-disable-next-line no-restricted-syntax
            for (const name of currDirPath) {
                children = children.find(
                    (item: SafeAny) => item.label === name
                        && item.type === 'folder',
                ).children;
            }

            return children;
        };

        const generateResultRow = (id: number, result: SafeAny) => {
            const resultEl = document.createElement('div');
            resultEl.setAttribute('key', `terminal-result-row-${id}`);
            resultEl.style.marginBottom = '.25rem';

            resultEl.innerHTML = result && Array.isArray(result) && result.length > 0
                ? result.join('&nbsp;&nbsp;<br/>')
                : result;

            const content = document.querySelector('#terminal-content');
            content?.appendChild(resultEl);

            const t = {
                ...store.getters.get(
                    'applications',
                    'terminal',
                ),
            };
            store.dispatch('updateApplication', {
                id: 'terminal',
                content: [...t.content, resultEl],
            });
        };

        const generateInputRow = (id: number) => {
            const terminalEl = document.querySelector('#terminal-content');
            const row = document.createElement('div');
            row.setAttribute('key', `terminal-input-row-${id}`);
            row.style.marginTop = '.25rem';

            if (id === 0) {
                const welcome = document.createElement('span');
                // eslint-disable-next-line operator-linebreak
                welcome.innerHTML =
                    'Hey, you found the terminal! Type `help` to get started.';

                if (terminalEl) {
                    terminalEl.appendChild(welcome);
                }
            }

            const pcName = document.createElement('span');
            pcName.innerHTML = 'can@Macbook-Pro';
            pcName.style.color = 'rgba(253,230,138,1)';

            const currDir = document.createElement('span');
            currDir.style.marginLeft = '.75rem';
            currDir.innerHTML = getCurrDirName();

            const separator = document.createElement('span');
            separator.style.marginLeft = '.75rem';
            separator.style.display = 'inline-flex';
            separator.innerHTML = '%';

            const input = document.createElement('input');
            input.setAttribute('id', `terminal-input-${id}`);
            // eslint-disable-next-line no-use-before-define
            input.addEventListener('keydown', keypress);
            input.setAttribute('autofocus', 'true');
            input.setAttribute('autocomplete', 'off');
            input.setAttribute('spellcheck', 'false');
            input.style.background = 'transparent';
            input.style.outline = 'none';
            input.style.border = 'none';
            input.style.color = 'white';
            input.style.marginLeft = '.75rem';
            input.style.minWidth = '100%';

            separator.appendChild(input);
            currDir.appendChild(separator);
            pcName.appendChild(currDir);
            row.appendChild(pcName);

            if (terminalEl) {
                terminalEl.appendChild(row);
            }

            if (input) {
                input.focus();
            }
        };

        const autoComplete = (text: string) => {
            if (text === '') return text;

            const input = text.split(' ');
            const cmd = input[0];
            const args = input[1];

            let result = text;

            if (args === undefined) {
                const guess = Object.keys(commands)
                    .find(
                        // eslint-disable-next-line comma-dangle
                        (item) => item.toLowerCase()
                            .substring(0, cmd.length) === cmd,
                    );
                if (guess !== undefined) result = guess;
            } else if (
                cmd === 'cd'
                || cmd === 'cat'
            ) {
                const type = cmd === 'cd'
                    ? 'folder'
                    : 'file';

                const guess = currChildren.find(
                    (item: SafeAny) => item.type === type
                        && item.label
                            .toLowerCase()
                            .substring(0, args.length) === args.toLowerCase(),
                );
                if (guess !== undefined) {
                    result = `${cmd} ${guess.label}`;
                }
            }
            return result;
        };

        const clear = () => {
            currInputTimes.value += 1;
            reset();
        };

        const keypress = (e: KeyboardEvent) => {
            const keyCode = e.key;
            const $input: SafeAny = document.querySelector(
                `#terminal-input-${currInputTimes.value}`,
            );
            const inputText = $input ? $input.value.trim() : '';
            const input = inputText.split(' ');
            const currTerminal = {
                ...store.getters.get(
                    'applications',
                    'terminal',
                ),
            };

            if (keyCode === 'Enter') {
                // ----------- run command -----------
                history.push(inputText);

                const cmd = input[0];
                const args = input[1];

                if ($input) {
                    // we can't edit the past input
                    $input.setAttribute('readonly', 'true');
                }

                if (inputText.substr(0, 6) === 'rm -rf') {
                    currTerminal.rmrf = true;
                    store.dispatch('updateApplication', {
                        id: 'applications',
                        currTerminal,
                    });
                    generateResultRow(
                        currInputTimes.value,
                        '<img src="img/rmrf.jpeg" alt="rmrf" id="rmrf" width="100%" />',
                    );

                    const el = document.getElementById('rmrf');

                    if (el) {
                        el.scrollIntoView({
                            behavior: 'smooth',
                        });
                    }
                } else if (cmd && Object.keys(commands)
                    .includes(cmd)) {
                    (commands as SafeAny)[cmd](args);
                } else if (!cmd) {
                    generateResultRow(
                        currInputTimes.value,
                        '',
                    );
                } else {
                    generateResultRow(
                        currInputTimes.value,
                        `zsh: command not found: ${cmd}`,
                    );
                }

                // point to the last history command
                currHistory = history.length;

                // generate new input row
                currInputTimes.value += 1;
                // eslint-disable-next-line no-use-before-define
                generateInputRow(currInputTimes.value);
            } else if (keyCode === 'ArrowUp') {
                // previous
                if (history.length > 0) {
                    if (currHistory > 0) {
                        currHistory -= 1;
                    }
                    $input.value = history[currHistory];
                }
            } else if (keyCode === 'ArrowDown') {
                // ----------- next history command -----------
                if (history.length > 0) {
                    if (currHistory < history.length) {
                        currHistory += 1;
                    }
                    if (currHistory === history.length) {
                        $input.value = '';
                    } else {
                        $input.value = history[currHistory];
                    }
                }
            } else if (keyCode === 'Tab') {
                // ----------- auto complete -----------
                $input.value = autoComplete(inputText);
                // prevent tab outside the terminal
                e.preventDefault();
            } else if (keyCode === 'l' && e.ctrlKey) {
                clear();
                generateInputRow(currInputTimes.value);
                e.preventDefault();
            }
        };

        const focusOnInput = () => {
            const input = document.querySelector(
                `#terminal-input-${currInputTimes.value}`,
            ) as HTMLElement;

            if (input) {
                input.focus();
            }
        };

        const ls = () => {
            const result: SafeAny = [];

            currChildren.forEach((item: SafeAny) => result.push(item.label));

            generateResultRow(currInputTimes.value, result);
        };

        const cd = (args: SafeAny = null) => {
            if (args === undefined || args === '~') {
                // move to root
                currDirPath = [];
                currChildren = terminal;
            } else if (args === '.') {
                // stay in the current folder
            } else if (args === '..') {
                // move to parent folder
                if (currDirPath.length === 0) return;
                currDirPath.pop();
                currChildren = getCurrChildren();
            } else {
                // move to certain child folder
                const target = currChildren.find(
                    // eslint-disable-next-line comma-dangle
                    (item: SafeAny) => item.label === args
                        && item.type === 'folder',
                );
                if (target === undefined) {
                    generateResultRow(
                        currInputTimes.value,
                        // eslint-disable-next-line comma-dangle
                        `cd: no such file or directory ${args}`
                    );
                } else {
                    currChildren = target.children;
                    currDirPath.push(target.label);
                }
            }
        };

        const cat = (args: SafeAny = null) => {
            const file = currChildren.find(
                (item: SafeAny) => item.label === args
                    && item.type === 'file',
            );

            if (!file) {
                generateResultRow(
                    currInputTimes.value,
                    'No such file or directory',
                );
            } else {
                generateResultRow(currInputTimes.value, file.content);
            }
        };

        const help = () => {
            generateResultRow(
                currInputTimes.value,
                `<ul>
          <li><span style="color: rgb(207, 53, 53)">cat {file}</span> - See the content of {file}</li>
          <li><span style="color: rgb(207, 53, 53)">cd {dir}</span> - Move into {dir}, "cd .." to move to parent directory, "cd" or "cd ~" to return to root</li>
          <li><span style="color: rgb(207, 53, 53)">ls</span> - See files and directories in current directory</li>
          <li><span style="color: rgb(207, 53, 53)">clear</span> - Clear terminal</li>
          <li><span style="color: rgb(207, 53, 53)">help</span> - Display help menu</li>
          <li><span style="color: rgb(207, 53, 53)">rm -rf /</span> - Just try it</li>
          <li><span style="color: rgb(207, 53, 53)">press up/down arrow</span> - Select history commands</li>
          <li><span style="color: rgb(207, 53, 53);">press tab</span> - Auto complete</li>
        </ul>`,
            );
        };

        onMounted(() => {
            commands = {
                cd,
                ls,
                cat,
                clear,
                help,
            };

            getConfigs();
            reset();

            setTimeout(() => {
                generateInputRow(currInputTimes.value);
            }, 500);
        });

        return {
            isLoading,
            focusOnInput,
        };
    },
});

import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import { SafeAny } from '@/types/safe-any.type';

export default defineComponent({
    name: 'ApplicationComponent',
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const store: SafeAny = useStore();

        const close = (): void => {
            store.dispatch('closeWindow', props.id);
        };

        return {
            close,
        };
    },
});

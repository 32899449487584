<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 96.000000 96.000000"
    preserveAspectRatio="xMidYMid meet">

    <g
      transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
      class="fill-black dark:fill-white"
      stroke="none">
      <path
        d="M187 853 c-4 -3 -7 -13 -7 -22 0 -9 134 -150 299 -315 299 -299 329
-323 339 -272 3 14 -78 101 -294 317 -165 165 -306 299 -315 299 -9 0 -19 -3
-22 -7z" />
      <path
        d="M412 771 l-33 -6 48 -46 c43 -42 55 -48 118 -59 86 -14 154 -44 222
-98 l52 -42 30 26 c17 14 31 34 31 45 0 10 -22 36 -52 60 -120 95 -289 144
-416 120z" />
      <path
        d="M148 662 c-75 -56 -82 -79 -37 -116 l31 -26 39 34 c21 19 57 45 80
56 l41 22 -38 39 c-21 21 -41 39 -45 39 -5 0 -36 -21 -71 -48z" />
      <path
        d="M332 540 c-58 -26 -112 -69 -112 -89 0 -16 48 -71 62 -71 3 0 29 16
57 35 29 19 67 37 86 41 19 3 35 9 35 13 0 4 -19 26 -42 48 l-43 42 -43 -19z" />
      <path
        d="M660 485 c63 -64 80 -71 80 -35 0 20 -42 56 -97 84 -53 27 -50 19 17
-49z" />
      <path
        d="M425 356 c-16 -7 -39 -23 -50 -36 l-20 -22 58 -54 c32 -30 62 -54 67
-54 5 0 34 24 65 53 65 60 65 75 1 107 -49 23 -79 25 -121 6z" />
    </g>
  </svg>
</template>

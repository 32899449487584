import { SafeAny } from '@/types/safe-any.type';
import { Application } from '@/types/application.interface';

export const getApplications = ({ commit }: SafeAny, orderBy?: string): void => {
    commit('getApplications', orderBy);
};

export const updateApplication = ({ commit }: SafeAny, payload: Application): void => {
    commit('updateApplication', payload);
};

export const setApplication = ({ commit }: SafeAny, payload: Application) => {
    commit('setApplication', payload);
};

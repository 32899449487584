import { SafeAny } from '@/types/safe-any.type';
import { Notification } from '@/types/notification.interface';

export const addNotification = ({ commit }: SafeAny, notification: Notification): void => {
    const currNotification = { ...notification };

    if (currNotification.duration !== undefined) {
        commit('addNotification', currNotification);

        if (currNotification.duration !== 0) {
            setTimeout(() => {
                commit('closeNotification', currNotification);
            }, +currNotification.duration);
        }
    } else {
        currNotification.duration = 3000;
        commit('addNotification', currNotification);

        setTimeout(() => {
            commit('closeNotification', currNotification);
        }, +currNotification.duration);
    }
};

export const updateNotification = ({ commit }: SafeAny, notification: Notification): void => {
    const currNotification = { ...notification };

    if (currNotification.duration !== undefined) {
        commit('updateNotification', notification);

        if (currNotification.duration !== 0) {
            setTimeout(() => {
                commit('closeNotification', notification);
            }, +currNotification.duration);
        }
    } else {
        currNotification.duration = 3000;
        commit('updateNotification', currNotification);

        setTimeout(() => {
            commit('closeNotification', currNotification);
        }, +currNotification.duration);
    }
};

export const closeNotification = ({ commit }: SafeAny, notification: Notification): void => {
    commit('closeNotification', notification);
};

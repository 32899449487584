import {
    defineComponent, reactive, ref,
} from 'vue';
import ArrowRightIcon from '@/components/Icon/ArrowRightIcon.vue';

export default defineComponent({
    name: 'AccordionComponent',
    components: {
        ArrowRightIcon,
    },
    setup() {
        const isExpanded = reactive(ref(true));

        return {
            isExpanded,
        };
    },
});

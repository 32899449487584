<template>
  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="12" width="12" xmlns="http://www.w3.org/2000/svg"><path stroke="black" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="64" d="M368 368L144 144m224 0L144 368" /></svg>
</template>
<style lang="scss">
.dark .fill-white {
    path {
        @apply stroke-white;
    }
}
</style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50.000000pt"
    height="50.000000pt"
    viewBox="0 0 50.000000 50.000000"
    preserveAspectRatio="xMidYMid meet">

    <g
      transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
      stroke="none">
      <path
        d="M85 447 c-3 -7 -4 -100 -3 -207 l3 -195 165 0 165 0 0 205 0 205
-163 3 c-126 2 -164 0 -167 -11z m275 -77 l0 -40 -110 0 -110 0 0 40 0 40 110
0 110 0 0 -40z m-180 -130 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13 7
20 20 20 13 0 20 -7 20 -20z m60 0 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20
0 13 7 20 20 20 13 0 20 -7 20 -20z m60 0 c0 -13 -7 -20 -20 -20 -13 0 -20 7
-20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z m60 0 c0 -13 -7 -20 -20 -20 -13 0
-20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z m-180 -60 c0 -13 -7 -20 -20
-20 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z m60 0 c0 -13 -7
-20 -20 -20 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z m60 0 c0
-13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z
m60 0 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0 20 -7
20 -20z m-180 -60 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13 7 20 20 20
13 0 20 -7 20 -20z m60 0 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13 7 20
20 20 13 0 20 -7 20 -20z m60 0 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0
13 7 20 20 20 13 0 20 -7 20 -20z m60 0 c0 -13 -7 -20 -20 -20 -13 0 -20 7
-20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z" />
      <path
        d="M240 370 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z" />
      <path
        d="M280 370 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z" />
      <path
        d="M320 370 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z" />
    </g>
  </svg>
</template>

import {
    computed, defineComponent, onUpdated, reactive, ref,
} from 'vue';
import { useStore } from 'vuex';
import { SafeAny } from '@/types/safe-any.type';
import { Message } from '@/types/message.interface';
import MediaHelper from '@/utils/media-helper';
import DateHelper from '@/utils/date-helper';

import Spinner from '@/components/Spinner/SpinnerComponent.vue';
import ArrowRightIcon from '@/components/Icon/ArrowRightIcon.vue';
import ArrowLeftIcon from '@/components/Icon/ArrowLeftIcon.vue';

export default defineComponent({
    name: 'MessageContentComponent',
    components: {
        Spinner,
        ArrowRightIcon,
        ArrowLeftIcon,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const store: SafeAny = useStore();
        const mediaHelper = new MediaHelper();
        const dateHelper = new DateHelper();
        const selectedMessage = reactive(ref());
        const iconsLoaded = reactive(ref(false));
        const isDesktop = computed(() => !mediaHelper.isMobile());
        const isOnline = computed(() => store.getters.get('isOnline'));

        const onSelectMessage = (msg: Message, idx?: number): void => {
            const messageApplication = store.getters.get('applications', 'messages') as Message;

            if (idx && messageApplication.messages && messageApplication.messages[idx]) {
                (messageApplication.messages[idx] as SafeAny).unreadMessageCount = 0;
            }

            if (msg) {
                msg.messages.forEach((message: SafeAny) => {
                    const date = new Date();

                    // eslint-disable-next-line no-param-reassign
                    message.time = new Date(date);
                });
            }

            if (!isDesktop.value) {
                iconsLoaded.value = false;
            }

            selectedMessage.value = msg;

            setTimeout(() => {
                const el = document.getElementById(
                    `message-${selectedMessage.value.messages.length - 1}`,
                );

                if (el) {
                    el.scrollIntoView({
                        behavior: 'smooth',
                    });
                }
            }, 300);
        };

        const messages: SafeAny = computed(() => {
            const mssgs = (store.getters.get('applications', 'messages') as Message)?.messages;

            if (!selectedMessage.value && messages && isDesktop.value && mssgs && mssgs[0]) {
                const message = mssgs[0] as unknown as Message;
                onSelectMessage(message);
            }

            return mssgs;
        });

        const getTime = (): string => dateHelper.formatDate(new Date(), {
            hour: '2-digit',
            minute: '2-digit',
        });

        const convertDate = (d: string, time = false): string => {
            if (time) {
                return dateHelper.formatDate(new Date(d), {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                    weekday: 'short',
                });
            }

            return dateHelper.formatDate(new Date(d), {
                hour: '2-digit',
                minute: '2-digit',
            });
        };

        const isNewDate = (current: string, prev: string): boolean => !(
            new Date(current).getDate() === new Date(prev).getDate()
        );

        const onBack = (): void => {
            selectedMessage.value = null;
            iconsLoaded.value = false;
        };

        onUpdated(() => {
            if (selectedMessage.value?.messages?.length > 6) {
                const el = document.getElementById(
                    `message-${selectedMessage.value.messages.length - 1}`,
                );

                if (el) {
                    el.scrollIntoView({
                        behavior: 'smooth',
                    });
                }
            }
        });

        return {
            isOnline,
            messages,
            selectedMessage,
            iconsLoaded,
            isDesktop,
            getTime,
            onSelectMessage,
            convertDate,
            isNewDate,
            onBack,
        };
    },
});

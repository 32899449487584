import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import { State } from '@/types/state.interface';
import { Application } from '@/types/application.interface';
import FirebaseService from '@/services/firebase.service';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SafeAny } from '@/types/safe-any.type';

export const getApplications = (state: State, orderBy = 'displayOrder'): void => {
    FirebaseService
        .getCollection('application', orderBy)
        .then((snapshot: QuerySnapshot<DocumentData>) => {
            const applications: Application[] = [];

            snapshot.forEach((doc: SafeAny) => {
                applications.push({
                    id: doc.id,
                    label: doc.data().label,
                    icon: doc.data().icon,
                    link: doc.data().link,
                    desktop: { ...doc.data().desktop },
                    mobile: { ...doc.data().mobile },
                });
            });

            state.applications = applications;
        });
};

export const updateApplication = (state: State, payload: Application): void => {
    const applicationIndex = state.applications.findIndex((app) => app.id === payload.id);

    if (applicationIndex > -1) {
        state.applications[applicationIndex] = {
            ...state.applications[applicationIndex],
            ...payload,
        };
    }
};

export const setApplication = (state: State, payload: Application): void => {
    const applicationIndex = state.applications.findIndex((app) => app.id === payload.id);

    if (applicationIndex > -1) {
        state.applications[applicationIndex] = payload;
    }
};

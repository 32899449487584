import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b4631b3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { open: "" }
const _hoisted_2 = { class: "py-3" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("details", _hoisted_1, [
    _createElementVNode("summary", {
      class: "flex justify-between items-center cursor-pointer outline-none px-3 list-none list-none",
      onClick: _cache[0] || (_cache[0] = $event => (_ctx.isExpanded = !_ctx.isExpanded))
    }, [
      _renderSlot(_ctx.$slots, "accordion-header", {}, undefined, true),
      (_openBlock(), _createBlock(_resolveDynamicComponent("ArrowRightIcon"), {
        class: _normalizeClass(["text-blue-500 duration-300", { 'rotate-90': _ctx.isExpanded }])
      }, null, 8, ["class"]))
    ]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "accordion-content", {}, undefined, true)
    ])
  ]))
}
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-screen h-screen bg-cover bg-desktop dark:bg-desktop-dark" }
const _hoisted_2 = { class: "absolute bottom-1 w-full flex" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_resolveDynamicComponent("Navbar")))
    ]),
    (_ctx.isLaunchpadOpen)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent("Launchpad"), { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.isMessagesOpen)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent("Messages"), { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.isSafariOpen)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent("Safari"), { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.isTerminalOpen)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent("Terminal"), { key: 3 }))
      : _createCommentVNode("", true),
    (_ctx.isVsCodeOpen)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent("VsCode"), { key: 4 }))
      : _createCommentVNode("", true),
    (_ctx.isAboutOpen)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent("About"), { key: 5 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_resolveDynamicComponent("Dock")))
    ]),
    (_openBlock(), _createBlock(_resolveDynamicComponent("Notification")))
  ], 64))
}
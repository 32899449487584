import {
    computed, defineComponent, onMounted, reactive, ref,
} from 'vue';
import { useStore } from 'vuex';
import { SafeAny } from '@/types/safe-any.type';
import Window from '@/components/Window/WindowComponent.vue';
import Spinner from '@/components/Spinner/SpinnerComponent.vue';
import Error from '@/components/Error/ErrorComponent.vue';

export default defineComponent({
    name: 'AboutComponent',
    components: {
        Window,
        Spinner,
        Error,
    },
    setup() {
        const store: SafeAny = useStore();
        const version = computed(() => store.getters.get('version')?.substring(1));
        const isOnline = computed(() => store.getters.get('isOnline'));
        const { vendor, userAgentData } = navigator as SafeAny;
        const tab = ref(0);
        const isLoading = ref(true);
        const memory = reactive({
            usedJsHeapSize: 0,
            totalJsHeapSize: 0,
        });

        const onTabChange = (value: number): void => {
            if (value === tab.value) {
                return;
            }

            tab.value = value;
            isLoading.value = true;

            setTimeout(() => {
                isLoading.value = false;
            }, 1000);
        };

        onMounted(() => {
            setInterval(() => {
                memory.usedJsHeapSize = (
                    Math.round((window.performance as SafeAny).memory.usedJSHeapSize / (1024 ** 2))
                );
                memory.totalJsHeapSize = (
                    Math.round((window.performance as SafeAny).memory.totalJSHeapSize / (1024 ** 2))
                );
            }, 5000);
        });

        return {
            tab,
            version,
            vendor,
            userAgentData,
            isLoading,
            isOnline,
            memory,
            onTabChange,
        };
    },
});

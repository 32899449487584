import { defineComponent, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import MediaHelper from '@/utils/media-helper';
import { SafeAny } from '@/types/safe-any.type';
import { disableDarkReader } from '@/utils/dark-reader';
import { checkIfOnline, checkNetworkSpeed } from '@/utils/network';
import { BrowserHelper } from '@/utils/browser-helper';

export default defineComponent({
    name: 'App',
    setup() {
        const environmentData = reactive({
            breakpoint: 'xs',
            screenSize: '0',
            vue: '0.0.0',
            vuex: '0.0.0',
            typescript: '0.0.0',
            tailwind: '0.0.0',
            version: '0.0.0',
        });
        const mediaHelper = new MediaHelper();
        const browserHelper = new BrowserHelper();
        const store: SafeAny = useStore();
        const isProd = process.env.NODE_ENV === 'production';

        const setTheme = () => {
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                document.documentElement.classList.add('dark');
                store.dispatch('toggleTheme');
            } else {
                document.documentElement.classList.remove('dark');
            }
        };

        const checkBreakpoint = () => {
            environmentData.screenSize = `${window.innerWidth}x${window.innerHeight}`;
            environmentData.breakpoint = mediaHelper.getBreakpoint();
        };

        const getVersion = () => {
            environmentData.version = `v${process.env.VUE_APP_VERSION}`;
            environmentData.vue = `${process.env.VUE_APP_VUE_VERSION}`;
            environmentData.vuex = `${process.env.VUE_APP_VUEX_VERSION}`;
            environmentData.typescript = `${process.env.VUE_APP_TYPESCRIPT_VERSION}`;
            environmentData.tailwind = `${process.env.VUE_APP_TAILWIND_VERSION}`;
            store.dispatch('setVersion', environmentData.version);
        };

        onMounted(() => {
            checkNetworkSpeed(store);
            checkIfOnline(store);
            browserHelper.checkBrowser(store);
            disableDarkReader();
            checkBreakpoint();
            setTheme();
            getVersion();

            let isMobile = mediaHelper.isMobile();

            window.addEventListener('resize', () => {
                checkBreakpoint();

                if (
                    (isMobile && !mediaHelper.isMobile())
                    || (!isMobile && mediaHelper.isMobile())
                ) {
                    window.location.reload();
                    isMobile = false;
                }
            });

            store.dispatch('authorizeUser');
            store.dispatch('getSkills');
        });

        return {
            environmentData,
            isProd,
        };
    },
});

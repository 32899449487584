<template>
  <svg
    stroke="currentColor"
    fill="currentColor"
    stroke-width="0"
    viewBox="0 0 512 512"
    height="16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="32"
      d="M336 192h40a40 40 0 0140 40v192a40 40 0 01-40 40H136a40 40 0
            01-40-40V232a40 40 0 0140-40h40m160-64l-80-80-80 80m80 193V48"
    />
  </svg>
</template>

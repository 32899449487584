import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Transition as _Transition, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "absolute inset-0 flex flex-col items-center justify-center bg-white dark:bg-black" }
const _hoisted_2 = ["aria-valuenow"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent("AppleIcon"), { class: "w-48 h-48" })),
    _withDirectives(_createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          role: "progressbar",
          "aria-valuemax": "100",
          "aria-valuemin": "0",
          "aria-valuenow": _ctx.progressValue,
          class: "relative w-1/3 md:w-1/4 xl:w-1/12 h-1.5 bg-black/10 dark:bg-white/10 rounded-md mt-5"
        }, [
          _createElementVNode("div", {
            class: "absolute inset-0 bg-black dark:bg-gray-200 rounded-md",
            style: _normalizeStyle([{"width":"inherit","height":"inherit"}, { width: `${_ctx.progressValue}%` }])
          }, null, 4)
        ], 8, _hoisted_2)
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.progressValue > 0]
    ])
  ]))
}
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48.000000pt"
    height="48.000000pt"
    viewBox="0 0 48.000000 48.000000"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)">
      <path
        d="M90 370 c-12 -7 -15 -34 -15 -130 0 -96 3 -123 15 -130 20 -13 280
-13 300 0 12 7 15 34 15 130 0 96 -3 123 -15 130 -20 13 -280 13 -300 0z m280
-150 l0 -90 -130 0 -130 0 0 90 0 90 130 0 130 0 0 -90z" />
    </g>
  </svg>
</template>

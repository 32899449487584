import { State } from '@/types/state.interface';
import { Notification } from '@/types/notification.interface';

export const addNotification = (state: State, notification: Notification): void => {
    const updatedNotification = { ...notification };

    if (notification.seen === undefined) {
        updatedNotification.seen = false;
    }

    state.notifications.unshift(updatedNotification);
};

export const updateNotification = (state: State, notification: Notification): void => {
    const notificationIndex = state.notifications.findIndex(
        (not) => not.id?.toString() === notification.id?.toString(),
    );
    const updatedNotification = { ...notification };

    if (notificationIndex > -1) {
        state.notifications[notificationIndex] = {
            ...state.notifications[notificationIndex],
            ...updatedNotification,
        };
    }
};

export const closeNotification = (state: State, notification: Notification): void => {
    const notificationIndex = state.notifications.findIndex(
        (not) => not.id?.toString() === notification.id?.toString(),
    );

    if (notificationIndex > -1) {
        state.notifications[notificationIndex] = {
            ...notification,
            seen: true,
        };
    }
};

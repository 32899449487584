import { State } from '@/types/state.interface';
import { Skill } from '@/types/skill.interface';
import FirebaseService from '@/services/firebase.service';

export const setSkills = (state: State, skills: Skill[]): void => {
    state.skills = skills;
};

export const getSkills = (state: State): void => {
    FirebaseService.getCollection('skill')
        .then((snapshot) => {
            const skls: Skill[] = [];

            snapshot.forEach((doc) => {
                skls.push({
                    id: doc.id,
                    label: doc.data().label,
                    icon: doc.data().icon,
                });
            });

            state.skills = skls;
        });
};

import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import { SafeAny } from '@/types/safe-any.type';
import { getRandomId } from '@/utils/random-helper';
import Navbar from '@/components/Navbar/NavbarComponent.vue';
import Dock from '@/components/Dock/DockComponent.vue';
import Launchpad from '@/components/Launchpad/LaunchpadComponent.vue';
import Messages from '@/components/Message/MessageComponent.vue';
import Safari from '@/components/Safari/SafariComponent.vue';
import Terminal from '@/components/Terminal/TerminalComponent.vue';
import VsCode from '@/components/VsCode/VsCodeComponent.vue';
import Notification from '@/components/Notification/NotificationComponent.vue';
import About from '@/components/About/AboutComponent.vue';

export default defineComponent({
    name: 'DesktopView',
    components: {
        Navbar,
        Dock,
        Launchpad,
        Messages,
        Safari,
        Terminal,
        VsCode,
        Notification,
        About,
    },
    setup() {
        const store: SafeAny = useStore();
        const imagePaths = computed(() => store.getters.get('imagePaths'));
        const isLaunchpadOpen = computed(() => store.getters.get('applications', 'launchpad')?.isVisible);
        const isMessagesOpen = computed(() => store.getters.get('applications', 'messages')?.isVisible);
        const isSafariOpen = computed(() => store.getters.get('applications', 'safari')?.isVisible);
        const isTerminalOpen = computed(() => store.getters.get('applications', 'terminal')?.isVisible);
        const isVsCodeOpen = computed(() => store.getters.get('applications', 'vscode')?.isVisible);
        const isAboutOpen = computed(() => store.getters.get('applications', 'about')?.isVisible);

        store.dispatch('getApplications');

        setTimeout(() => {
            store.dispatch('addNotification', {
                id: getRandomId().toString(),
                title: 'Deprecated',
                body: '<a href="https://www.alicandirik.com">This version is deprecated and not maintained anymore. Click here to check new version.</a>',
                icon: 'WarningIcon',
                duration: 0,
                date: new Date().toISOString(),
            });
        }, 2000);

        return {
            imagePaths,
            isLaunchpadOpen,
            isMessagesOpen,
            isSafariOpen,
            isTerminalOpen,
            isVsCodeOpen,
            isAboutOpen,
        };
    },
});

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 52.000000 60.000000"
    width="15"
    preserveAspectRatio="xMidYMid meet">

    <g
      transform="translate(0.000000,60.000000) scale(0.100000,-0.100000)"
      class="fill-dark dark:fill-gray-200"
      stroke="none">
      <path
        d="M79 557 c-68 -46 -76 -142 -15 -203 40 -40 84 -48 233 -42 84 3 114
8 136 22 78 52 76 183 -3 230 -23 13 -57 16 -173 16 -133 0 -147 -2 -178 -23z
m340 -43 c61 -51 31 -144 -52 -159 -19 -4 -82 -5 -140 -3 -95 3 -108 5 -131
27 -14 13 -28 36 -31 52 -7 31 15 82 42 98 10 6 77 11 150 11 127 0 132 -1
162 -26z" />
      <path
        d="M115 495 c-30 -29 -32 -68 -5 -95 41 -41 120 -12 120 45 0 31 -41 75
-70 75 -11 0 -32 -11 -45 -25z" />
      <path
        d="M83 244 c-33 -16 -63 -66 -63 -104 0 -36 28 -85 59 -103 40 -24 307
-25 351 -2 79 41 79 169 0 210 -42 22 -302 21 -347 -1z m343 -47 c37 -33 37
-81 0 -114 -15 -12 -38 -23 -51 -23 -38 0 -78 41 -78 80 0 24 7 40 27 57 34
29 68 29 102 0z" />
    </g>
  </svg>
</template>

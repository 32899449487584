import { State } from '@/types/state.interface';

// eslint-disable-next-line import/no-unresolved,@typescript-eslint/no-var-requires,global-require
const audio = new Audio('https://firebasestorage.googleapis.com/v0/b/portfolio-cb128.appspot.com/o/music.mp3?alt=media&token=c4437188-3b38-428e-87ab-891da0e6bc66');
audio.loop = true;

const state: State = {
    version: 'x.x.x',
    isOnline: true,
    isAuthenticated: false,
    isDesktop: false,
    config: {
        wifi: {
            isOpen: false,
            isLoading: false,
            label: 'Wi-Fi',
            description: 'Home',
        },
        cellularData: {
            isOpen: true,
        },
        flightMode: {
            isOpen: false,
        },
        bluetooth: {
            isOpen: true,
            isLoading: false,
            label: 'Bluetooth',
            description: 'Can\'s Airpods Pro',
        },
        airdrop: {
            isOpen: true,
            isLoading: false,
            label: 'Airdrop',
            description: 'Contacts only',
        },
        theme: {
            isDark: false,
            label: 'Dark Theme',
        },
        fullscreen: {
            isOpen: false,
            label: 'Enter Fullscreen',
        },
        display: {
            label: 'Display',
            brightness: 100,
        },
        sound: {
            label: 'Sound',
            level: 10,
        },
        music: {
            audio,
            name: 'Unchained Melody',
            creator: 'Righteous Brothers',
            isPlaying: false,
        },
    },
    applications: [],
    windows: {},
    lastActiveWindow: null,
    notifications: [],
};

export default state;

import { State } from '@/types/state.interface';

export * from './control-center/control-center.mutations';
export * from './application/application.mutations';
export * from './user/user.mutations';
export * from './window/window.mutations';
export * from './skill/skill.mutations';
export * from './notification/notification.mutations';
export * from './message/message.mutations';

export const setViewType = (state: State, payload: boolean): void => {
    state.isDesktop = payload;
};

export const setVersion = (state: State, payload: string): void => {
    state.version = payload;
};

export const setOnlineStatus = (state: State, payload: boolean): void => {
    state.isOnline = payload;
};

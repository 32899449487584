import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57e70d86"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "dock",
  class: "os-dock__wrapper"
}
const _hoisted_2 = ["id", "onClick"]
const _hoisted_3 = { class: "os-dock__tooltip" }
const _hoisted_4 = ["src", "onLoad"]

export function render(_ctx, _cache) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.applications, (app) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "os-dock__item",
        key: app.id,
        id: app.id,
        onClick: $event => (_ctx.openApplication(app))
      }, [
        _createElementVNode("span", _hoisted_3, [
          _createElementVNode("strong", null, _toDisplayString(app.label), 1)
        ]),
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createElementBlock("img", {
              alt: "dock-app",
              class: "w-20 xl:w-24 mx-auto md:ml-0 first:ml-0",
              width: "24",
              height: "24",
              key: 'os-dock-' + app.id,
              src: app.icon,
              onLoad: $event => (_ctx.onLoad(app))
            }, null, 40, _hoisted_4)), [
              [_vShow, app.iconLoaded]
            ])
          ]),
          _: 2
        }, 1024),
        (!app.iconLoaded)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent("Spinner"), {
              key: 'spinner-' + app.id,
              class: "xs:w-20 xl:w-24 mx-auto md:ml-0 first:ml-0 flex justify-center"
            }))
          : _createCommentVNode("", true),
        ((app.id !== 'github' && app.id !== 'mail' && app.id !== 'linkedin') && app.isVisible)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass({
            'os-dock__dot': app.isVisible
        })
            }, null, 2))
          : _createCommentVNode("", true)
      ], 8, _hoisted_2))
    }), 128))
  ], 512)), [
    [_vShow, _ctx.applications?.length > 0]
  ])
}
import { State } from '@/types/state.interface';
import FirebaseService from '@/services/firebase.service';
import { Message } from '@/types/message.interface';

export const getMessages = (state: State): void => {
    FirebaseService.getCollection('message')
        .then((snapshot) => {
            const messages: Message[] = [];

            snapshot.forEach((doc) => {
                const data = doc.data() as Message;

                messages.push({
                    sender: data.sender,
                    profilePhoto: data.profilePhoto,
                    messages: data.messages ? [...data.messages] : [],
                    unreadMessageCount: data.unreadMessageCount,
                });
            });

            if (messages?.length > 0) {
                const applicationIndex = state.applications.findIndex((app) => app.id === 'messages');

                if (applicationIndex > -1) {
                    state.applications[applicationIndex] = {
                        ...state.applications[applicationIndex],
                        messages: [...messages],
                    };
                }
            }
        })
        .catch((e) => {
            console.log(`%cCATCH ERROR >> ${e}`, 'background-color:teal;color:white;font-size:1rem');
            const applicationIndex = state.applications.findIndex((app) => app.id === 'messages');

            if (applicationIndex > -1) {
                state.applications[applicationIndex] = {
                    ...state.applications[applicationIndex],
                    error: e,
                };
            }
        });
};

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32.000000pt"
    height="32.000000pt"
    viewBox="0 0 32.000000 32.000000"
    preserveAspectRatio="xMidYMid meet">

    <g
      transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
      stroke="none">
      <path
        d="M265 268 c-3 -8 -4 -61 -3 -119 3 -104 3 -104 28 -104 l25 0 0 115
c0 112 -1 115 -23 118 -12 2 -24 -3 -27 -10z" />
      <path
        d="M182 208 c-14 -14 -16 -136 -3 -156 5 -8 17 -12 27 -10 16 3 19 14
22 82 2 57 -1 82 -10 87 -17 12 -22 11 -36 -3z" />
      <path
        d="M86 155 c-9 -24 -7 -88 3 -103 5 -8 17 -12 27 -10 16 3 19 12 19 63
0 54 -2 60 -21 63 -13 2 -24 -4 -28 -13z" />
      <path
        d="M4 126 c-3 -8 -4 -29 -2 -48 2 -28 7 -33 28 -33 27 0 37 26 26 71 -7
27 -43 34 -52 10z" />
    </g>
  </svg>
</template>

import {
    computed, defineComponent, ref,
} from 'vue';
import { useStore } from 'vuex';
import { SafeAny } from '@/types/safe-any.type';
import { Application } from '@/types/application.interface';
import Navbar from '@/components/Navbar/NavbarComponent.vue';
import Dock from '@/components/Dock/DockComponent.vue';
import Spinner from '@/components/Spinner/SpinnerComponent.vue';
import Messages from '@/components/Message/MessageComponent.vue';
import Safari from '@/components/Safari/SafariComponent.vue';
import Files from '@/components/Files/FilesComponent.vue';

export default defineComponent({
    name: 'MobileView',
    components: {
        Navbar,
        Dock,
        Spinner,
        Messages,
        Safari,
        Files,
    },
    setup() {
        const store: SafeAny = useStore();
        const iconsLoaded = ref(false);
        // eslint-disable-next-line no-param-reassign
        const apps = computed(() => (store.getters.get('applications') as Application[])
            ?.filter(
                (app: SafeAny) => app.id === 'github'
                || app.id === 'mail'
                || app.id === 'linkedin',
            ) || []);
        const isMessagesOpen = computed(() => store.getters.get('applications', 'messages')?.isVisible);
        const isSafariOpen = computed(() => store.getters.get('applications', 'safari')?.isVisible);
        const isFilesOpen = computed(() => store.getters.get('applications', 'files')?.isVisible);

        store.dispatch('getApplications');

        const onLoad = (): void => {
            iconsLoaded.value = true;
        };

        return {
            apps,
            iconsLoaded,
            isMessagesOpen,
            isSafariOpen,
            isFilesOpen,
            onLoad,
        };
    },
});

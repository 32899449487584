import {
    defineAsyncComponent, defineComponent, onMounted, ref, watchEffect,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { SafeAny } from '@/types/safe-any.type';
import MediaHelper from '@/utils/media-helper';

export default defineComponent({
    name: 'SplashScreen',
    components: {
        AppleIcon: defineAsyncComponent(() => import('@/components/Icon/AppleIcon.vue')),
    },
    setup() {
        const store: SafeAny = useStore();
        const router = useRouter();
        const mediaHelper = new MediaHelper();
        const progressValue = ref(0);

        const checkWindowSize = () => {
            if (mediaHelper.isMobile()) {
                store.dispatch('setViewType', false);
                router.push({
                    path: '/mobile',
                });
            } else {
                store.dispatch('setViewType', true);
                router.push({
                    path: '/desktop',
                });
            }
        };

        watchEffect(() => {
            const is = store.getters.get('isAuthenticated');

            if (is) {
                const interval = setInterval(() => {
                    if (progressValue.value === 100) {
                        clearInterval(interval);
                        checkWindowSize();
                    }
                }, 15);
            }
        });

        onMounted(() => {
            store.dispatch('authorizeUser');

            let isMobile = mediaHelper.isMobile();

            window.addEventListener('resize', () => {
                checkWindowSize();

                if (
                    (isMobile && !mediaHelper.isMobile())
                    || (!isMobile && mediaHelper.isMobile())
                ) {
                    window.location.reload();
                    isMobile = false;
                }
            });

            setTimeout(() => {
                const interval = setInterval(() => {
                    if (progressValue.value >= 100) {
                        progressValue.value = 100;
                        clearInterval(interval);
                    } else {
                        progressValue.value += 1;
                    }
                }, 15);
            }, 500);
        });

        return {
            progressValue,
        };
    },
});

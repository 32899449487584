<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48.000000pt"
    height="48.000000pt"
    viewBox="0 0 48.000000 48.000000"
    preserveAspectRatio="xMidYMid meet">

    <g
      transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
      fill="#FAB005"
      stroke="none">
      <path
        d="M162 384 c-99 -51 -111 -211 -19 -276 41 -30 123 -36 171 -13 103 49
117 211 23 277 -41 29 -129 35 -175 12z m93 -115 c0 -41 -4 -54 -15 -54 -16 0
-27 73 -16 102 13 33 31 5 31 -48z m1 -90 c10 -17 -13 -36 -27 -22 -12 12 -4
33 11 33 5 0 12 -5 16 -11z" />
    </g>
  </svg>
</template>

import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withKeys as _withKeys, vModelText as _vModelText, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "h-full xl:w-full hidden xl:block" }
const _hoisted_2 = { class: "flex justify-center items-center h-[5rem] py-5 px-5 bg-gray-100 border-b-[1px] border-gray-400/50 dark:bg-neutral-800 dark:border-b-[1px] dark:border-neutral-900 xl:h-[45px] xl:shadow-xl xl:justify-between" }
const _hoisted_3 = { class: "xl:flex xl:ml-24" }
const _hoisted_4 = {
  disabled: "",
  class: "z-20",
  name: "sidebar-button"
}
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  disabled: "",
  class: "ml-3 z-20",
  name: "forward-button"
}
const _hoisted_7 = { class: "xl:flex" }
const _hoisted_8 = {
  disabled: "",
  class: "z-20",
  name: "share-button"
}
const _hoisted_9 = { class: "xl:h-[calc(100%-3rem)] backdrop-blur-3xl bg-gray-100/75 dark:bg-zinc-800/90 overflow-y-hidden" }
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 1,
  class: "flex justify-center xl:first:py-5 xl:first:px-10"
}
const _hoisted_12 = {
  key: 2,
  class: "w-3/4 xl:mx-auto xl:mt-20"
}
const _hoisted_13 = { class: "xl:text-lg" }
const _hoisted_14 = { class: "xl:flex xl:mt-3" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "text-neutral-500" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "xl:mt-3" }
const _hoisted_21 = { class: "absolute inset-0 block xl:hidden bg-gray-200 dark:bg-neutral-900" }
const _hoisted_22 = { class: "mt-14 h-[calc(100%-12rem)] overflow-y-auto px-5" }
const _hoisted_23 = ["src"]
const _hoisted_24 = {
  key: 1,
  class: "flex justify-center"
}
const _hoisted_25 = { key: 2 }
const _hoisted_26 = { class: "text-lg font-bold" }
const _hoisted_27 = ["onClick"]
const _hoisted_28 = ["src"]
const _hoisted_29 = {
  key: 0,
  class: "text-neutral-500"
}
const _hoisted_30 = { class: "absolute bottom-0 left-0 right-0 px-5 pb-8 bg-gray-300/50 dark:bg-white/10 border-t-[1px] border-gray-300 dark:border-white/10 flex items-center justify-center flex-wrap" }
const _hoisted_31 = { class: "w-full flex items-center justify-between mt-3" }
const _hoisted_32 = ["disabled"]
const _hoisted_33 = {
  disabled: "",
  name: "forward-button"
}
const _hoisted_34 = {
  disabled: "",
  name: "share-button"
}
const _hoisted_35 = {
  disabled: "",
  name: "tab-button"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", _hoisted_4, [
            (_openBlock(), _createBlock(_resolveDynamicComponent("SidebarIcon"), { class: "text-gray-400 dark:text-neutral-600" }))
          ]),
          _createElementVNode("button", {
            class: "group bg-transparent border-none flex items-center justify-center cursor-pointer w-10 ml-3 h-10 z-20",
            name: "bookmark-button",
            onClick: _cache[0] || (_cache[0] = $event => (_ctx.goToBookmarks())),
            disabled: !_ctx.website || _ctx.website === 'bookmarks'
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent("ArrowLeftIcon"), { class: "dark:group-disabled:text-neutral-600 dark:text-neutral-400 group-disabled:text-gray-400 text-gray-700" }))
          ], 8, _hoisted_5),
          _createElementVNode("button", _hoisted_6, [
            (_openBlock(), _createBlock(_resolveDynamicComponent("ArrowRightIcon"), { class: "text-gray-400 dark:text-neutral-600" }))
          ])
        ]),
        _withDirectives(_createElementVNode("input", {
          placeholder: "Search or enter website name",
          class: "z-20 text-center rounded-lg outline-none placeholder:text-neutral-500 focus:border-blue-400 focus:border-2 bg-gray-400/20 text-gray-700 xl:mr-24 xl:w-1/3 xl:h-7 xl:dark:bg-neutral-700/50 xl:dark:text-gray-100",
          spellcheck: "false",
          translate: "no",
          onKeyup: _cache[1] || (_cache[1] = _withKeys($event => (_ctx.goToUrl()), ["enter"])),
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.website) = $event))
        }, null, 544), [
          [_vModelText, _ctx.website]
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", _hoisted_8, [
            (_openBlock(), _createBlock(_resolveDynamicComponent("ShareIcon"), { class: "text-gray-400 dark:text-neutral-600" }))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        (_ctx.url !== 'bookmarks')
          ? (_openBlock(), _createElementBlock("iframe", {
              key: 0,
              height: "100%",
              width: "100%",
              src: _ctx.url
            }, null, 8, _hoisted_10))
          : _createCommentVNode("", true),
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              (_openBlock(), _createBlock(_resolveDynamicComponent("Spinner")))
            ]))
          : _createCommentVNode("", true),
        (_ctx.url === 'bookmarks')
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (conf) => {
                return _withDirectives((_openBlock(), _createElementBlock("div", {
                  class: "xl:mb-16",
                  key: conf.title
                }, [
                  _createElementVNode("span", _hoisted_13, _toDisplayString(conf.title.charAt(0).toUpperCase() + conf.title.substring(1)), 1),
                  _createElementVNode("ul", _hoisted_14, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(conf.content, (link, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, {
                        key: link.label
                      }, [
                        (link.description)
                          ? (_openBlock(), _createElementBlock("li", {
                              key: 0,
                              class: _normalizeClass(["xl:flex xl:list-none xl:w-1/4 xl:cursor-pointer xl:items-center xl:rounded-xl xl:shadow xl:p-4 xl:bg-gray-100/60 xl:dark:bg-neutral-900/60", { 'mr-8': index !== conf.content.length - 1 }]),
                              onClick: $event => {_ctx.website = link.url; _ctx.goToUrl()}
                            }, [
                              _createElementVNode("img", {
                                src: link.icon,
                                alt: "link-icon",
                                class: "xl:w-14 xl:h-14 xl:mr-3"
                              }, null, 8, _hoisted_16),
                              _createElementVNode("div", null, [
                                _createElementVNode("div", null, _toDisplayString(link.label), 1),
                                _createElementVNode("div", _hoisted_17, _toDisplayString(link.description), 1)
                              ])
                            ], 10, _hoisted_15))
                          : _createCommentVNode("", true),
                        (!link.description)
                          ? (_openBlock(), _createElementBlock("li", {
                              key: 1,
                              class: "xl:list-none xl:cursor-pointer xl:items-center xl:rounded-lg xl:text-center xl:w-15 xl:h-15 xl:mr-10 xl:first:ml-0",
                              onClick: $event => {_ctx.website = link.url; _ctx.goToUrl()}
                            }, [
                              _createElementVNode("img", {
                                src: link.icon,
                                alt: "link-icon",
                                class: "xl:w-14 xl:h-14 xl:m-auto drop-shadow"
                              }, null, 8, _hoisted_19),
                              _createElementVNode("div", _hoisted_20, _toDisplayString(link.label), 1)
                            ], 8, _hoisted_18))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  ])
                ])), [
                  [_vShow, !_ctx.isLoading]
                ])
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("div", _hoisted_22, [
        (!_ctx.isLoading && _ctx.url !== 'bookmarks')
          ? (_openBlock(), _createElementBlock("iframe", {
              key: 0,
              height: "100%",
              width: "100%",
              src: _ctx.url
            }, null, 8, _hoisted_23))
          : _createCommentVNode("", true),
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
              (_openBlock(), _createBlock(_resolveDynamicComponent("Spinner")))
            ]))
          : _createCommentVNode("", true),
        (_ctx.url === 'bookmarks')
          ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (conf) => {
                return _withDirectives((_openBlock(), _createElementBlock("div", {
                  class: "mt-8",
                  key: conf.title
                }, [
                  _createElementVNode("span", _hoisted_26, _toDisplayString(conf.title.charAt(0).toUpperCase() + conf.title.substring(1)), 1),
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(conf.content, (link) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: link.label,
                        class: "flex p-3 bg-white dark:bg-white/10 mt-2 rounded-xl",
                        onClick: $event => {_ctx.website = link.url; _ctx.goToUrl()}
                      }, [
                        _createElementVNode("img", {
                          src: link.icon,
                          alt: "link-icon",
                          class: "w-10 h-10"
                        }, null, 8, _hoisted_28),
                        _createElementVNode("div", {
                          class: _normalizeClass(["ml-3", { 'flex items-center': !link.description }])
                        }, [
                          _createElementVNode("div", null, _toDisplayString(link.label), 1),
                          (link.description)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_29, _toDisplayString(link.description), 1))
                            : _createCommentVNode("", true)
                        ], 2)
                      ], 8, _hoisted_27))
                    }), 128))
                  ])
                ])), [
                  [_vShow, !_ctx.isLoading]
                ])
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_30, [
        _withDirectives(_createElementVNode("input", {
          placeholder: "Search or enter website name",
          class: _normalizeClass(["rounded-xl outline-none w-full mt-2 p-3 placeholder:text-neutral-500 dark:bg-white/10 text-left", { 'text-center': _ctx.website && _ctx.url !== 'bookmarks' }]),
          spellcheck: "false",
          translate: "no",
          onKeyup: _cache[3] || (_cache[3] = _withKeys($event => (_ctx.goToUrl()), ["enter"])),
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.website) = $event))
        }, null, 34), [
          [_vModelText, _ctx.website]
        ]),
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("button", {
            class: "group bg-transparent border-none flex items-center justify-center",
            name: "bookmark-button",
            onClick: _cache[5] || (_cache[5] = $event => (_ctx.goToBookmarks())),
            disabled: !_ctx.website || _ctx.website === 'bookmarks'
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent("ArrowLeftIcon"), { class: "dark:group-disabled:text-neutral-600 dark:text-blue-500 group-disabled:text-gray-400 text-blue-500 w-8 h-8" }))
          ], 8, _hoisted_32),
          _createElementVNode("button", _hoisted_33, [
            (_openBlock(), _createBlock(_resolveDynamicComponent("ArrowRightIcon"), { class: "text-gray-400 dark:text-neutral-600 w-8 h-8" }))
          ]),
          _createElementVNode("button", _hoisted_34, [
            (_openBlock(), _createBlock(_resolveDynamicComponent("ShareIcon"), { class: "text-gray-400 dark:text-neutral-600 w-8 h-8" }))
          ]),
          _createElementVNode("button", {
            onClick: _cache[6] || (_cache[6] = $event => (_ctx.goToBookmarks())),
            name: "bookmark-button"
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent("BookmarkIcon"), { class: "fill-blue-500 w-8 h-8" }))
          ]),
          _createElementVNode("button", _hoisted_35, [
            (_openBlock(), _createBlock(_resolveDynamicComponent("TabsIcon"), { class: "fill-gray-400 dark:fill-neutral-600 w-8 h-8" }))
          ])
        ])
      ])
    ])
  ], 64))
}
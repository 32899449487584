import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["name", "aria-label"]
const _hoisted_3 = ["id"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", {
    class: "relative inline-block",
    id: _ctx.id + '-dropdown'
  }, [
    _createElementVNode("button", {
      type: "button",
      class: "flex items-center justify-center w-full rounded-lg",
      name: _ctx.id,
      tabindex: "-1",
      onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args))),
      "aria-label": _ctx.id,
      "aria-expanded": "true",
      "aria-haspopup": "true"
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_2),
    _createElementVNode("div", {
      class: _normalizeClass(["absolute block bg-gray-200/90 dark:bg-zinc-800/70 backdrop-blur dark:backdrop-blur-3xl mt-2 shadow-md border border-[1px] border-gray-400 dark:border-zinc-600 focus:outline-none rounded-xl p-1 z-50", [
             _ctx.placement === 'right' ? 'right-0' : 'left-0',
             !_ctx.reactives.isDropdownOpen ? 'hidden' : '',
             _ctx.menuWidth
         ]]),
      role: "menu",
      "aria-orientation": "vertical",
      tabindex: "0",
      id: _ctx.id + '-dropdown-menu'
    }, [
      _renderSlot(_ctx.$slots, "dropdown-menu")
    ], 10, _hoisted_3)
  ], 8, _hoisted_1))
}
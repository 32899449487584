<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.000000pt"
    height="24.000000pt"
    viewBox="0 0 24.000000 24.000000"
    preserveAspectRatio="xMidYMid meet">

    <g
      transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
      stroke="none">
      <path
        d="M14 183 c-18 -43 -13 -97 12 -124 25 -28 26 -18 4 26 -21 41 -19 72
8 113 10 15 11 22 3 22 -7 0 -19 -17 -27 -37z" />
      <path
        d="M202 198 c27 -41 29 -72 8 -113 -11 -21 -18 -40 -16 -42 8 -8 37 38
42 69 7 38 -18 108 -37 108 -8 0 -7 -7 3 -22z" />
      <path
        d="M56 178 c-18 -26 -21 -61 -6 -89 18 -33 29 -21 13 13 -15 29 -15 34
1 61 20 36 14 47 -8 15z" />
      <path
        d="M162 195 c2 -5 10 -22 17 -37 11 -22 11 -31 -2 -57 -17 -33 -5 -44
13 -12 16 31 12 67 -11 92 -11 13 -19 20 -17 14z" />
      <path
        d="M103 163 c-15 -6 -17 -49 -3 -58 6 -3 10 -24 10 -46 0 -21 5 -39 10
-39 6 0 10 18 10 39 0 22 5 43 10 46 16 10 12 52 -6 59 -9 3 -16 6 -17 5 -1 0
-8 -3 -14 -6z" />
    </g>
  </svg>
</template>

import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isDesktop)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent("Window"), {
          key: 0,
          id: _ctx.id,
          width: 1280,
          height: 720
        }, {
          default: _withCtx(() => [
            (_ctx.isOnline)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent("SafariContent"), { key: 0 }))
              : (_openBlock(), _createBlock(_resolveDynamicComponent("Error"), { key: 1 }))
          ]),
          _: 1
        }, 8, ["id"]))
      : _createCommentVNode("", true),
    (!_ctx.isDesktop)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent("Application"), {
          key: 1,
          id: _ctx.id
        }, {
          default: _withCtx(() => [
            (_ctx.isOnline)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent("SafariContent"), { key: 0 }))
              : (_openBlock(), _createBlock(_resolveDynamicComponent("Error"), { key: 1 }))
          ]),
          _: 1
        }, 8, ["id"]))
      : _createCommentVNode("", true)
  ], 64))
}
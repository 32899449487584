import { defineComponent } from 'vue';

export default defineComponent({
    name: 'SpinnerComponent',
    props: {
        isSpinning: {
            type: Boolean,
            default: true,
        },
        text: {
            type: String,
            default: '',
        },
    },
});

import FirebaseService from '@/services/firebase.service';
import { SafeAny } from '@/types/safe-any.type';

export const authorizeUser = (state: SafeAny): void => {
    FirebaseService
        .authorize()
        .then(() => {
            state.isAuthenticated = true;
        });
};

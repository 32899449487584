import { computed, defineComponent, reactive } from 'vue';
import { useStore } from 'vuex';
import { WINDOW_INITIAL_POSITIONS } from '@/types/window.const';
import { SafeAny } from '@/types/safe-any.type';
import { Application } from '@/types/application.interface';
import { getRandomId } from '@/utils/random-helper';

import CloseIcon from '@/components/Icon/CloseIcon.vue';
import MinimizeIcon from '@/components/Icon/MinimizeIcon.vue';
import MaximizeIcon from '@/components/Icon/MaximizeIcon.vue';

export default defineComponent({
    name: 'WindowComponent',
    components: {
        CloseIcon,
        MinimizeIcon,
        MaximizeIcon,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        width: {
            type: Number,
            default: null,
        },
        height: {
            type: Number,
            default: null,
        },
        showTitle: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const store: SafeAny = useStore();
        let isDraggingActive = false;
        const { left, top } = (WINDOW_INITIAL_POSITIONS as SafeAny)[props.id.toUpperCase()];
        let xOffset = 0;
        let yOffset = 0;
        let currentX = 0;
        let currentY = 0;
        let initialX: number;
        let initialY: number;

        const data = reactive({
            zIndex: computed(() => store.getters.get('windows', props.id)),
            getLeft: computed(() => left),
            getTop: computed(() => top),
        });

        const bringToFront = (): void => {
            store.dispatch('setCurrentWindow', store.getters.get('applications', props.id));
        };

        const setTitle = (id: string): string => id[0].toUpperCase() + id.slice(1);

        const maximize = (): void => {
            const app: Application = store.getters.get('lastActiveWindow');

            store.dispatch('addNotification', {
                id: getRandomId().toString(),
                seen: false,
                application: app?.label,
                title: 'Great!',
                body: 'You found a missing feature :)',
                date: new Date().toISOString(),
                icon: app?.icon,
            });
        };

        const minimize = (): void => {
            setTimeout(() => {
                store.dispatch('minimizeWindow', props.id);
            }, 100);
        };

        const close = (): void => {
            setTimeout(() => {
                store.dispatch('closeWindow', props.id);
                document.getElementById(props.id)?.classList.remove('os-dock__item--opening');
            }, 100);
        };

        const startDrag = (e: MouseEvent): void => {
            bringToFront();

            initialX = e.clientX - xOffset;
            initialY = e.clientY - yOffset;

            const header = document.getElementById(`${props.id}-header`);

            if (e.target === header) {
                isDraggingActive = true;
            }
        };

        const endDrag = (e: MouseEvent): void => {
            initialX = e.clientX;
            initialY = currentY;
            isDraggingActive = false;
        };

        const drag = (e: MouseEvent): void => {
            if (isDraggingActive) {
                e.preventDefault();
                currentX = e.clientX - initialX;
                currentY = e.clientY - initialY;

                xOffset = currentX;
                yOffset = currentY;
                const el = document.getElementById(`${props.id}-window`);

                if (el) {
                    el.style.transform = `translate3d(${currentX}px, ${currentY}px, 0)`;
                }
            }
        };

        return {
            data,
            isDraggingActive,
            bringToFront,
            startDrag,
            drag,
            endDrag,
            minimize,
            maximize,
            close,
            setTitle,
        };
    },
});

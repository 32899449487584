<template>
  <svg
    stroke="currentColor"
    fill="none"
    stroke-width="2"
    viewBox="0 0 24 24"
    stroke-linecap="round"
    stroke-linejoin="round"
    height="16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polyline
      points="6.5 6.5 17.5 17.5 12 23 12 1 17.5 6.5 6.5 17.5"
    />
  </svg>
</template>

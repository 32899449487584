import { SafeAny } from '@/types/safe-any.type';
import { State } from '@/types/state.interface';
import MediaHelper from '@/utils/media-helper';

const mediaHelper = new MediaHelper();

export const changeDisplayLevel = (state: SafeAny, payload: number): void => {
    document.documentElement.style.filter = `brightness(${payload / 100})`;
    state.config.display.brightness = payload;
};

export const toggleMusic = (state: State): void => {
    const { music, sound } = state.config;
    const audio = music.audio as HTMLMediaElement;

    music.isPlaying = !music.isPlaying;

    if (music.isPlaying) {
        audio.load();

        const promise = audio.play();
        if (promise !== undefined) {
            promise
                .then(() => {
                    audio.volume = sound.level / 100;
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    } else {
        audio.pause();
    }
};

export const changeSoundLevel = (state: SafeAny, payload: number): void => {
    const { music } = state.config;
    const audio = music.audio as HTMLMediaElement;

    state.config.sound.level = payload;
    audio.volume = payload / 100;
};

export const changeTheme = (): void => {
    const element = document.documentElement.classList;

    if (element.contains('dark')) {
        element.remove('dark');
    } else {
        element.add('dark');
    }
};

export const toggleWifi = (state: State): void => {
    const { wifi } = state.config;
    const isDesktop = !mediaHelper.isMobile();

    if (!wifi.isOpen) {
        wifi.description = 'Connecting..';

        setTimeout(() => {
            if (isDesktop) {
                wifi.description = 'Home';
            } else {
                wifi.description = 'Searching for Wi-Fi Networks';
            }
        }, 500);
    } else {
        wifi.description = isDesktop ? 'Off' : 'Disconnecting Nearby Wi-Fi Networks';
    }

    wifi.isOpen = !wifi.isOpen;

    if (!isDesktop) {
        setTimeout(() => {
            wifi.description = '';
        }, 2000);
    }
};

export const toggleFlightMode = (state: State): void => {
    const { flightMode } = state.config;
    flightMode.isOpen = !flightMode.isOpen;
};

export const toggleCellularData = (state: State): void => {
    const { cellularData } = state.config;
    cellularData.isOpen = !cellularData.isOpen;
};

export const toggleBluetooth = (state: State): void => {
    const { bluetooth } = state.config;
    const isDesktop = !mediaHelper.isMobile();

    if (!bluetooth.isOpen) {
        bluetooth.description = 'Connecting..';

        setTimeout(() => {
            if (isDesktop) {
                bluetooth.description = 'Can\'s Airpods Pro';
            } else {
                bluetooth.description = 'Bluetooth Connections Enabled';
            }
        }, 500);
    } else {
        bluetooth.description = isDesktop ? 'Off' : 'Disconnecting Bluetooth Devices';
    }

    bluetooth.isOpen = !bluetooth.isOpen;

    if (!isDesktop) {
        setTimeout(() => {
            bluetooth.description = '';
        }, 2000);
    }
};
export const toggleAirdrop = (state: State): void => {
    const { airdrop } = state.config;
    const isDesktop = !mediaHelper.isMobile();

    if (!airdrop.isOpen) {
        airdrop.description = 'Connecting..';

        setTimeout(() => {
            if (isDesktop) {
                airdrop.description = 'Contacts only';
            } else {
                airdrop.description = 'Airdrop is open for only contacts';
            }
        }, 500);
    } else {
        airdrop.description = isDesktop ? 'Off' : 'Disconnecting Nearby Devices';
    }

    airdrop.isOpen = !airdrop.isOpen;

    if (!isDesktop) {
        setTimeout(() => {
            airdrop.description = '';
        }, 2000);
    }
};

export const toggleTheme = (state: State): void => {
    const { theme } = state.config;

    theme.isDark = !theme.isDark;

    if (!theme.isDark) {
        document.documentElement.classList.remove('dark');
        theme.label = 'Light Theme';
    } else {
        document.documentElement.classList.add('dark');
        theme.label = 'Dark Theme';
    }
};
